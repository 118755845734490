import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import financeiroImg from '../../assets/img/consultoria.png'
import suporteImg from '../../assets/img/linkpro.png'
import gesttorImg from '../../assets/img/gesttor.png'
import './servicos.css'

function Servicos () {

    useEffect (() => {
        document.title = 'Hg Consultoria'
      }, [])
    
    return (
        <div id = 'containerServicos'>
            
            <h1 id = 'servicoTitulo'>Serviços</h1>

            <article id = 'servicosContent'>
                <section>
                    <img className='iconeServico' src={financeiroImg} alt='consultoria em gestão financeira do hg' title='serviço de consultoria em gestão financeira do hg' />
                    <h1>Consultoria Empresarial</h1>
                    <hr />
                    <br />
                    <p>Potencialize os resultados da sua empresa. Trabalhamos em conjunto com a empresa nas áreas de: <b>gestão financeira, gestão de pessoas, planejamento estratéfico e plano de negócios</b>. Você escolhe uma dessas áreas ou monta um pacote com várias consultorias para avalançar a sua empresa. Clique abaixo e agende um <b>diagnóstico gratuito</b>.</p>
                    <Link id = 'btnAcaoServico' to='/contato'>Agendar Diagnóstico</Link>
                </section>
                <section>
                    <img className='iconeServico' src={gesttorImg} alt = 'sistema gesttor do hg consultoria, sistema para sua empresa de serviços, marketing digital, ageência digital e contabilidade' title='sistema gesttor do hg consultoria, sistema para sua empresa de serviços, marketing digital, ageência digital e contabilidade' />
                    <h1>Sistema Gesttor</h1>
                    <hr />
                    <br />
                    <p>Sistema on-line para sua empresa de serviços. Gerencie suas tarefas e processos, tenha controle do seu financeiro e com os relatórios disponíveis você terá mais dados para melhores descisões. Ideal para <b>agências de marketing digital ou empresas de consultoria</b>. Trabalhe de forma mais eficiente com entregas pontuais das demandas de seus clientes.</p>
                    <a id='btnAcaoServico' href="https://gesttor.co/novaOrganizacao" target='_blanck'>Experimente Grátis</a>
                </section>

                <section>
                    <img className='iconeServico' src={suporteImg} alt = 'sistema para gestão e automação comercial para sua empresa' title='sistema linkpro para empresas' />
                    <h1>Sistema LinkPro</h1>
                    <hr />
                    <br />
                    <p>É um sistema para gestão da sua empresa. O LinkPro é ERP para facilitar o dia a dia da empresa com a emissão de notas fiscais, relatórios de vendas e gestão do estoque. Sistema prático, simples de usar e com diversos relatórios para sua tomada de decisão. Ideal para <b>lojas em geral, bares e restaurantes e muito mais</b>. Agende agora mesmo uma demonstração gratuita.</p>
                    <Link id = 'btnAcaoServico' to='/contato'>Agendar Demostração</Link>
                </section>
            </article>
        </div>
    )
}

export default Servicos