import { useEffect } from 'react'
import './termosuso.css'

function TermosUso () {

    useEffect (() => {
        document.title = 'Termo de Uso'
    }, [])
    
    return (
        <div id = 'paginaPoliticaPrivacidade'>
            <article id = 'conteudoContienerPrivacidade'>
                <h1 id='tituloPoliticaPrivacidade'>Termos de Uso</h1>
                <span>
                    <p>Este Contrato de Licença de Usuário Final ("EULA") é um acordo legal entre licenciado (pessoa Física ou Jurídica) denominado <b>LICENCIADO</b> e <b>SILVA SERVIÇOS EMPRESARIAIS LTDA</b>, pessoa jurídica de direito privado, inscrita no CNPJ nº 19.306.845/0001 - 38, com sede na Rua Boa Vista - 595, Camoxinga, Santana do Ipanema - AL, CEP: 57500 - 000, doravante denominado LICENCIANTE, para uso do programa de computador denominado <b>SISTEMA GESTTOR</b>, disponibilizado neste ato pela LICENCIANTE (o “SOFTWARE”), pelo prazo determinado pelo LICENCIADO no ato do licenciamento do SOFTWARE, mediante as cláusulas e condições seguintes.</p>

                    <h2>1. DO OBJETO</h2>

                    <p>O presente instrumento tem como objeto o direito de uso por prazo determinado no ato do licenciamento do SOFTWARE, que abrange o programa de computador e pode incluir quaisquer materiais impressos, e qualquer documentação “on-line” ou eletrônica, assim como outros sistemas disponibilizados pela <b>LICENCIANTE</b>. Ao utilizar o SOFTWARE, mesmo que parcial ou a título de teste, o <b>LICENCIADO</b> estará vinculado aos termos deste EULA, concordando com os mesmos de forma integral. Em caso de discordância dos termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida</p>

                    <p>Sujeito aos termos e condições do presente instrumento, este EULA concede ao LICENCIADO uma licença renovável, não exclusiva e intransferível para usar o <b>SOFTWARE</b>. O <b>LICENCIADO</b> não poderá utilizar e nem permitir o uso do <b>SOFTWARE</b> para outra finalidade que não seja o uso interno. Esta licença não implica na capacidade de acessar outros softwares além daqueles originalmente localizados no <b>SOFTWARE</b>. Em nenhuma hipótese o <b>LICENCIADO</b> terá acesso ao código fonte do <b>SOFTWARE</b> ora licenciado, por este se tratar de propriedade intelectual da <b>LICENCIANTE</b>.</p>

                    <p>A licença atribuída a este EULA restringe-se na utilização do <b>SOFTWARE</b>, para benefício próprio, e utilização dos recursos conforme plano adquirido, o <b>LICENCIADO</b> não adquire, pelo presente instrumento, nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou direitos sobre informações confidenciais ou segredos de negócio, sobre ou relacionados ao <b>SOFTWARE</b> ou nenhuma parte dele. O <b>LICENCIADO</b> também não adquire nenhum direito sobre ou relacionado ao <b>SOFTWARE</b> ou qualquer componente dele, além dos direitos expressamente licenciados ao mesmo sob o presente EULA ou em qualquer outro contrato mutuamente acordado por escrito que o <b>LICENCIADO</b> possa ter celebrado com a <b>LICENCIANTE</b>. Quaisquer direitos não expressamente concedidos sob o presente instrumento são reservados.</p>

                    <h3>1.1 DA LICENÇA</h3>

                    <p>Ao contratar o <b>SOFTWARE</b> o <b>LICENCIADO</b> adquire uma licença com validade de <b>30 dias</b> contados a partir do momento da contratação. Ainda no momento da contratação é gerado para o <b>LICENCIADO</b> uma assinatura de recorrência mensal com o valor da licença contratada cabendo exclusivamente ao <b>LICENCIADO</b> a responsabilidade pelo pagamento dessas faturas.</p>

                    <p>Dado a data de expiração da licença e o <b>LICENCIADO</b> não tenha pago o valor da assinatura definido no momento da contratação, o <b>SOFTWARE</b> ficará inacessível automaticamente. Sendo restabelecido o acesso, em aproximadamente, 24 horas úteis, após a confirmação do pagamento.</p>

                    <p>Ao ser confirmado o pagamento da assinatura mensal, o <b>LICENCADO</b> adquire automaticamente uma licença com validade de <b>30 dias</b> contados a partir do último dia da expiração da licença anterior.</p>

                    <h2>2. DECLARAÇÃO DE ACEITE</h2>
                    
                    <p>O <b>LICENCIANTE</b> declara ter conhecimento dos direitos e obrigações decorrentes do presente EULA, constituindo este instrumento o acordo completo entre as partes. Declara, ainda, ter lido, compreendido e aceito todos os termos e condições.</p>

                    <h2>3. DAS OBRIGAÇÕES DAS PARTES</h2>

                    <h3>3.1. OBRIGAÇÕES DO LICENCIADO</h3>
                    
                    <p>Manter equipe capacitada para a operação do <b>SOFTWARE</b> e para a comunicação com a <b>LICENCIANTE</b> e prover, sempre que ocorrerem quaisquer problemas com o <b>SOFTWARE</b>, toda a documentação e informações que relatem as circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os trabalhos, assim como quaisquer detalhes solicitados pela <b>LICENCIANTE</b>.</p>

                    <p>Manter, aos seus dispêndios, linha de telecomunicação, modem, software de comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com a LICENCIANTE e acesso ao SOFTWARE.</p>

                    <p>Responder pelas informações inseridas no <b>SOFTWARE</b>, pelo cadastramento, permissões, senhas e modo de uso de seus usuários. A <b>LICENCIANTE</b> em hipótese alguma será responsável pelo conteúdo (informações, senhas, cópias de informações, etc) sobre o <b>SOFTWARE</b>, não sendo, portanto, estas informações revisadas em momento algum. A responsabilidade pelas informações do <b>SOFTWARE</b> é sempre do <b>LICENCIADO</b>.</p>

                    <p>Efetuar os pagamentos da fatura gerada, mensalmente, de acordo com as condições estipuladas neste contrato ou alterações futuras solicitadas ao <b>LICENCIANTE</b>.</p>

                    <h3>3.2. OBRIGAÇÕES DA LICENCIANTE</h3>

                    <p>A <b>LICENCIANTE</b> garante ao <b>LICENCIADO</b> que o <b>SOFTWARE</b> deverá funcionar regularmente, se respeitadas às condições de uso definidas neste objeto. Na ocorrência de falhas de programação, a <b>LICENCIANTE</b> obrigar-se-á a corrigir tais falhas, podendo à seu critério, substituir a cópia dos programas com falhas por cópias corrigidas.</p>

                    <p>Fornecer, ato contínuo ao aceite deste EULA, acesso ao SOFTWARE pelo prazo estabelecido entre as partes.</p>

                    <p>Suspender o acesso ao SOFTWARE que esteja desrespeitando as cláusulas deste objeto ou as normas legais em vigor ou ainda, ao final do prazo de validade deste instrumento, independentemente de aviso prévio.</p>

                    <p>Alterar as especificações e/ou características do SOFTWARE licenciados para a melhoria e/ou correções de erros.</p>

                    <p>Disponibilizar acesso aos serviços de suporte através da ferramenta de help desk configurada no <b>SOFTWARE</b>, correio eletrônico (gesttor@meuhg.com), para esclarecimento de dúvidas de ordem não funcional diretamente relacionadas a problemas no <b>SOFTWARE</b>.</p>

                    <h3>3.3 A LICENCIADA se isenta de quaisquer obrigações nas situações</h3>

                    <p>Por falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa da <b>LICENCIANTE</b>.</p>

                    <p>Pelo cumprimento dos prazos legais do <b>LICENCIADO</b> para a entrega de documentos fiscais ou pagamentos de impostos.</p>

                    <p>Pelos danos ou prejuízos decorrentes de decisões administrativas, gerenciais ou comerciais tomadas com base nas informações fornecidas pelo SOFTWARE e por problemas definidos como “caso fortuito” ou “força maior” contemplados pelo Art. 393, do Código Civil Brasileiro.</p>
                    
                    <p>Em nenhum caso a <b>LICENCIANTE</b> será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o <b>SOFTWARE</b>, por qualquer outro motivo. Sob nenhuma circunstância a responsabilidade integral da <b>LICENCIANTE</b> com relação ao licenciado por todos os danos excederá a quantia paga pelo <b>LICENCIADO</b> à <b>LICENCIANTE</b> pela obtenção da presente licença de <b>SOFTWARE</b>.</p>

                    <h2>4. PRAZO DE VIGÊNCIA</h2>

                    <p>O presente EULA entra em vigor na data de seu aceite pelo LICENCIADO.</p>
                    <p>O prazo de licenciamento do <b>SOFTWARE</b> vigora por um período de 30 dias, sendo renovado automaticamente por igual período até que haja manifestação espressa por algum de nossos canais oficiais do <b>LICENCIADO</b> sobre o cancelamento dos serviços.</p>

                    <h2>5. DO PAGAMENTO</h2>

                    <p>O <b>LICENCIADO</b> deve pagar ao <b>LICENCIANTE</b> o valor do respectivo plano escolhido no ato da contratação de forma mensal sendo o primeiro pagamento no ato da contratação. A forma de pagamento deve ser escolhida dentre as disponibilizadas pela <b>LICENCIANTE</b>.</p>
                    <p>Caso o LICENCIADO, no decorrer da vigência do presente instrumento, opte por outro plano de licenciamento, os valores serão alterados de acordo com o respectivo plano escolhido.</p>
                    <p>A falta de pagamento nas datas determinadas para seu vencimento acarretará na suspensão de acesso ao SOFTWARE até que as pendências financeiras sejam regularizadas. Sem prejuízo para a <b>LICENCIANTE</b> pelo período em que o <b>SOFTWARE</b> esteve bloqueado.</p>
                    <p>Suspenso o acesso ao <b>SOFTWARE</b>, a <b>LICENCIANTE</b> manterá as informações do <b>LICENCIADO</b> lançadas no mesmo pelo período de 30 (trinta) dias, contados da suspensão de acesso.</p>
                    <p>Caso a suspensão permaneça por prazo superior a 30 (trinta) dias, a <b>LICENCIANTE</b> poderá excluir integralmente as informações lançadas no SOFTWARE pelo LICENCIADO.</p>
                    <p>Os valores estabelecidos no ato do licenciamento do <b>SOFTWARE</b> serão atualizados anualmente, tenho como mês base, agosto de cada ano e com efeitos a partir de setembro do mesmo ano. O reajuste será calculado com base no índice IGP-M/FGV.</p>

                    <h2>6. DAS RESTRIÇÕES</h2>

                    <p>Em hipótese alguma é permitido ao <b>LICENCIADO</b> ou a terceiros, de forma geral:</p>
                    <p>Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o SOFTWARE objeto deste EULA, assim como seus módulos, partes, manuais ou quaisquer informações relativas ao mesmo.</p>
                    <p>Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito existente no <b>SOFTWARE</b> e na documentação;</p>
                    <p>Praticar de engenharia reversa, descompilação ou desmontagem do <b>SOFTWARE</b>.</p>
                    <p>Estando totalmente sujeito a suspensão imediata da utilização do software e cancelamento do período de contratação, sem quaisquer restituições contratuais por parte da <b>LICENCIANTE</b>.</p>

                    <h2>7. RESCISÃO</h2>

                    <p>Em nenhum caso a <b>LICENCIANTE</b> será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o software, por qualquer outro motivo. Sob nenhuma circunstância a responsabilidade integral da <b>LICENCIANTE</b> com relação ao licenciado por todos os danos excederá a quantia paga pelo <b>LICENCIADO</b> à <b>LICENCIANTE</b> pela obtenção da presente licença de SOFTWARE.</p>
                    <p>Em caso de cancelamento e/ou desistência por parte do <b>LICENCIADO</b>, o valor pago pelo <b>LICENCIADO</b> só será restituído caso o cancelamento ocorra em até 7 (sete) dias a contar da data da contratação, não estendendo as assinaturas que são geradas mensalmente.</p>
                    <p>Os valaroes lançados no cadastro do <b>LICENCIADO</b> até a data da solicitação de cancelamento serão integralmente devidos a <b>LICENCIANTE</b> podendo esta usar dos meios legais para recuperação do crédito quando não houver o pagamento integral dos débitos em nome do <b>LICENCIADO</b>.</p>

                    <h2>8. DISPOSIÇÕES LEGAIS</h2>

                    <p>A <b>LICENCIANTE</b>, na execução do presente contrato, sem prejuízo de suas responsabilidades contratuais e legais poderá utilizar, se necessário, o apoio técnico especializado de terceiros, pessoas físicas ou jurídicas independentemente de prévia notificação ao <b>LICENCIADO</b>, desde que não haja custos posteriores ao <b>LICENCIADO</b>.</p>
                    <p>Caso o <b>LICENCIADO</b> venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, quer seja do dicionário de dados, quer seja do programa, será considerado como parte do software fornecido pela <b>LICENCIANTE</b>, ficando, portanto, sua propriedade incorporada pela <b>LICENCIANTE</b> e seu uso condicionado a estas cláusulas contratuais;</p>
                    <p>Este EULA obriga as partes e seus sucessores e somente o <b>LICENCIADO</b> possui licença não exclusiva para a utilização do <b>SOFTWARE</b>, sendo-lhe, entretanto, vedado transferir os direitos e obrigações impostos por este instrumento. Tal limitação, no entanto, não atinge a <b>LICENCIANTE</b>, que poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao presente EULA.</p>
                    <p>Não constituem causa de rescisão contratual, o não cumprimento das obrigações aqui assumidas em decorrência de fatos que independam da vontade das partes, tais como os que configuram o caso fortuito e a força maior previstos no artigo 393 do Código Civil Brasileiro.</p>
                    <p>Se qualquer disposição deste EULA for considerada nula, anulável, inválida ou inoperante, nenhuma outra disposição deste EULA será afetada como consequência disso e, portanto, as disposições restantes deste EULA permanecerão em pleno vigor e efeito como se tal disposição nula, anulável, inválida ou inoperante não estivesse contida neste EULA.</p>
                    <p>O <b>LICENCIADO</b> concorda que a <b>LICENCIANTE</b> possa divulgar o fechamento contrato para fins comerciais, fazendo menção ao nome e à marca do <b>LICENCIADO</b> em campanhas comerciais, podendo, inclusive, divulgar mensagens enviadas de forma escrita ou oral, por telefone, para uso em sites, jornais, revistas e outras campanhas, enquanto vigorar o presente EULA. O <b>LICENCIADO</b> aceita, ainda, receber notificações via correio eletrônico sobre treinamentos, parcerias e campanhas relacionadas ao <b>SOFTWARE</b>.</p>
                    <p>Neste ato, o <b>LICENCIANTE</b> expressamente autoriza a <b>LICENCIADA</b> a colher e utilizar seus dados técnicos e operacionais presentes no <b>SOFTWARE</b>, para fins de estudos e melhorias no <b>SOFTWARE</b>.</p>

                    <h2>9. FORO</h2>

                    <p>As <b>PARTES</b> elegem o Foro da cidade de Santana do Ipanema - AL, como único competente para dirimir as dúvidas ou controvérsias decorrentes deste Contrato.</p>

                    <p>Santana do Ipanema - AL, 10 de Agosto de 2023</p>

                    <h2>Última Revisão: <b>10/08/2023</b></h2>
                </span>
            </article>
        </div>
    )
}

export default TermosUso