import Cobranca from "../../components/Cobranca";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

function CobrancaPage () {

  return (
    <>
      <Header/>
      <Cobranca />
      <Footer/>
    </>
  )
}

export default CobrancaPage;