import './depoimentos.css'

function Depoimentos () {
    
    return (
        <div id = 'containerDepoimento'>
            
            <h1 id = 'depoimentoTitulo'>Depoimentos</h1>

            <section id = 'depoimentosClientesHg'>
                <span id = 'videoDepoimentoClientesHg'>
                    <iframe 
                        src="https://www.youtube.com/embed/nfrOESoery0" 
                        title="Depoimentos de Clientes do Hg"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                    </iframe>
                </span>
            </section>
        </div>
    )
}

export default Depoimentos