import { propsBtn, propsLoading } from '../../interfaces/propsBtn'
import styled from 'styled-components'
import coresBtn from "../../interfaces/coresBtn"
import coresDivSituacaoDefault from '../../interfaces/coresDivSituacaoDefault'
import divSituacaoDefaultProps from "../../interfaces/divSituacaoDefaultProps"

const btnCores: coresBtn = {
    continuarSucesso: '#25AE88',
    continuarErro: '#E21B1B', 
    cancelar: '#E21B1B', 
    contratar: '#25AE88',
    enviar: '#25AE88',
    continuar: '#001B48'
}

const coresDiv: coresDivSituacaoDefault = {
    aberta: '#0000FF',
    vencida: '#A41623',
}

export const DivSituacaoDefault = styled.div <divSituacaoDefaultProps> `
    background-color: ${(props) => coresDiv[props.cor]};
    width: 90%;
    min-width: 120px;
    color: #FFF;
    border-radius: 5px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin: 5px 0;
`

export const Button = styled.button <propsBtn>`
    position: relative;
    min-width: ${({ isCancelar }) => isCancelar ? '100px' : '150px'};
    height: 38px;
    padding: 5px 10px;
    border-radius: 10px;
    border: ${({ isCancelar, isLoading }) => {
        if(isLoading) return 'none'
        if(isCancelar && isLoading) return 'none'
        if(isCancelar) return '1px solid #E21B1B'

        return `none`
    }};
    background-color: ${({ isCancelar, isLoading, cor }) => {
        if(isLoading) return '#CCC'
        if(isCancelar) return '#EAF1F2'
        if(isCancelar && isLoading) return '#CCC'
        
        return btnCores[cor]
    }};
    color: ${({ isLoading, isCancelar }) => {
        if(isLoading) return '#999'
        if(isCancelar) return '#E21B1B'
        if(isCancelar && isLoading) return '#999'

        return '#FFF'
    }};
    cursor: ${({ isLoading }) => isLoading ? 'not-allowed' : 'pointer'};
    transition: all 500ms;

    &:hover {
        transform: scale(105%)
    }
`;

export const Loader = styled.span <propsLoading> `
    display: ${({ isLoading }) => isLoading ? 'block' : 'none'};

    &::after,
    &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent #F1F1FE #F1F1F3;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: AnimacaoLoader 500ms linear infinite;
        transform-origin: center center;
    }

    @keyframes AnimacaoLoader { 
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`