const CryptoJS = require("crypto-js")

const criptografarDado = (dado) => {

    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

    const dadoCriptografado = CryptoJS.AES.encrypt(dado, SECRET_KEY).toString()

    return encodeURIComponent(dadoCriptografado)
}

export default criptografarDado;