import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LinkProPage from "../../components/LinkPro";

function LinkPro () {

  return (
    <>
      <Header />
      <LinkProPage />
      <Footer />
    </>
  )
}

export default LinkPro;
