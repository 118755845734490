import axios  from 'axios'
import { tokenApi } from '../../middlewares/Token'

const BASE_URL = axios.create ({
    baseURL: process.env.REACT_APP_API_GESTTOR,
    headers: {
        'Content-Type': 'application/json',
        'idorganizacao': process.env.REACT_APP_ORGANIZACAO_HG
    }

})

BASE_URL.interceptors.request.use( config => {

    const token = tokenApi()

    if(token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

export default  BASE_URL