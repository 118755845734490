import Link from '../Menu'
import iconeFacebook from '../../assets/img/facebook.png'
import iconeInstagram from '../../assets/img/instagram.png'
import iconeYoutube from '../../assets/img/youtube.png'
import iconeLinkedin from '../../assets/img/linkedin.png'
import iconeGithub from '../../assets/img/github.png'
import iconeBehance from '../../assets/img/behance.png'
import iconeSpotify from '../../assets/img/spotify.png'
import './footer.css'

function Footer () {

    const target = '_blank'
  
    return (
        <footer>
            <div id = 'contienerRodape'>
                <div id = 'sobreHg'>
                    <strong>Sobre</strong>
                    <p>
                        Empresa de consultoria em gestão estratégica para crescimento das empresas. Estamos no mercado a mais de 04 anos desenvolvendo soluções empresariais consistentes e gerando resultados para nossos clientes, trabalhando com diversos, como: varejo, escolas, petshops, construção civil e clínicas médicas.
                    </p>

                </div>

                <div id = 'redesSociais'>
                    <b>Redes Sociais</b>
                    <span>
                        <a href="https://www.facebook.com/hgconsultoria" target= {target}><img src= {iconeFacebook} width="30px" alt="Página do Hg no Facebook"></img></a>

                        <a href="https://www.instagram.com/hgconsultoria" target= {target}><img src= {iconeInstagram} width="30px" alt="Página do Hg no Instagram"></img></a>

                        <a href="https://www.youtube.com/channel/UCnH75_kQD4mw8f_LdE7NO3g" target= {target}><img src= {iconeYoutube}width="30px" alt="Canal do Hg no Youtube"></img></a>

                        <a href="https://www.linkedin.com/company/hg-consultoria-empresarial/" target= {target}><img src= {iconeLinkedin} width="30px" alt="Página do Hg no Instagram"></img></a>

                        <a href="https://github.com/hgconsultoria" target= {target}><img src= {iconeGithub} width="30px" alt="Página do Hg no GitHub"></img></a>

                        <a href="https://www.behance.net/hgconsultoria" target= {target}><img src= {iconeBehance} width="30px" alt="Página do Hg no Behance"></img></a>

                        <a href="https://open.spotify.com/show/6sSyNgIHFTwaZ0Eo21iLiA?si=8MhS4Zw6RymJveTqc5CKKwnd=1" target= {target}><img src= {iconeSpotify}  width="30px" alt="Página do Hg no Spotify"></img></a>
                    </span>
                </div>

                <div id = 'enderecoRodape'>
                    <strong>Endereço</strong>
                    <p>Av Pancrácio Rocha - 1920, Camoxinga, Santana do Ipanema - AL</p>
                    <p>Contato: (82) 9.8224 - 4390</p>
                    <p>E-mail: hg@meuhg.com</p>
                </div>

                <div id = 'linksUteisRodape'>
                    <strong>Link Úteis</strong>
                    <p id = 'linkRodape'><Link  texto='Política de Privacidade' redirect='/politicaprivacidade' /></p>
                    <p id = 'linkRodape'><Link  texto='Termos de Uso' redirect='/termosuso' /></p>
                </div>
                
                <div id = 'direitosRodape'>
                    <strong>Direitos Reservados</strong>
                    <p>CNPJ: 19.306.845/0001 - 38</p>
                    <p>Silva Serviços Empresariais Ltda</p>
                    <p>Hg Consultoria</p>
                </div>

            </div>
        </footer>
    )
}

export default Footer