import { Link as LinkRoutes } from 'react-router-dom'
import { LinkProps } from '../../interfaces/Menu'
import './link.css'

function Link (props: LinkProps) {
    return (
        <LinkRoutes className='menuLink' to = {props.redirect}>
            {props.texto}
        </LinkRoutes>
    )
}

export default Link