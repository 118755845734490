import propsNotificacoes from '../../interfaces/propsNotificacoes'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import erroImg from '../../assets/img/erroFechar.png'
import './notificacoes.css'

function Notificacoes (props: propsNotificacoes) {

    return (
        <div className = {`notificacoesContainer ${props.cor}`}>
            <section className={`notificacoesContent ${props.cor}Content`}>
                <span>
                    <img src={props.cor === 'erro' ? erroImg : confirmacaoImg} alt=''/>
                </span>
                <span>
                    <p><b>{props.titulo}</b>{props.texto}</p>
                </span>
            </section>
        </div>
    )
}

export default Notificacoes