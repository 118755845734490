import { configureStore } from "@reduxjs/toolkit"
import atualizarDados from "./modules/atualizarDados"
import modalAlerta from "./modules/modalAlerta"
import modalQrCode from "./modules/modalQrCode"


const store = configureStore ({
    reducer: {
        atualizarDados,
        modalAlerta,
        modalQrCode
    }
})

export type RootStore = ReturnType <typeof store.getState>

export default store