import propsModalAlerta from '../../interfaces/propsModalAlerta'
import { Button } from '../styles'
import './modalalerta.css'

function ModalAlerta (props: propsModalAlerta) {

    return (
        <div id='modalAlertaFundo'>
            <section id = 'modalFundoDados'>
                <span>
                    <img src={props.icone} alt='' />
                </span>
                <p>{props.texto}</p>
                <div>
                    <Button isCancelar={false} type='button' onClick={props.acao} cor={props.cor} isLoading={false}>
                        {props.titulo}
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default ModalAlerta