import { useEffect } from 'react'
import escritorioImg from '../../assets/svg/resultados.svg'
import tarefasImg from '../../assets/img/tarefas.png'
import processosImg from '../../assets/img/processos.png'
import comercialImg from '../../assets/img/comercial.png'
import financeiroImg from '../../assets/img/financeiro.png'
import relatoriosImg from '../../assets/img/relatorios.png'
import './gesttor.css'

function Gesttor () {

    useEffect (() => {
        document.title = 'Sistema Gesttor | Hg'
    }, [])

    return (
        <main>
            <section id='introducaoHomeContenier'>
                <span>
                    <span id='chamacaoAcaoTitulo'>
                        <h1>Gerencie todas as suas tarefas e processos com agilidade e praticidade</h1>
                        <p>Conheça o <b>Sistema Gesttor</b>, experimente <b>GRÁTIS</b> por 15 dias e perceba como esta ferramenta é indispensável no seu dia a dia</p>
                    </span>
                    <span id = 'chamadaAcao'>
                        <a id='btnAcaoServico' href="https://gesttor.co/novaOrganizacao" target='_blanck'>Experimente Grátis</a>
                    </span>
                </span>
                <span>
                    <img id='escritorioHome'  src={escritorioImg} alt = 'sistema gesttor do hg' />
                </span>
            </section>

            <section id = 'apresentacaoSistemaGesttor'>
                <span>
                    <iframe 
                        src="https://www.youtube.com/embed/YfhOl2XSUek" 
                        title="Apresentação Sistema Gesttor"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                    </iframe>
                </span>
            </section>

            <section id = 'funcionalidadesGesttor'>
                <section>
                    <span>
                        <img src={tarefasImg} alt = 'tarefas controladas pelo sistema gesttor' title='tarefas controladas pelo sistema gesttor'/>
                        <p><b>Tarefas</b></p>
                        <p>Tenha controle total sobre as tarefas/demandas geradas pelos seus clientes. Gerencie e supervisione os trabalhos que estão sob responsabilidade de sua equipe. Não perca mais prazos, tudo está sobre a sua gestão. Com o módulo de tarefas você ainda pode:</p>
                        <li>Criar regras de recorrência das tarefas</li>
                        <li>Criar tarefas Avulsas</li>
                        <li>Encaminhamento entre responsáveis</li>
                    </span>

                    <span>
                        <img src={processosImg} alt = 'proccessos controladas pelo sistema gesttor' title='processos controladas pelo sistema gesttor'/>
                        <p><b>Processos</b></p>
                        <p>Crie processos e vincule tarefas conforme a necessidade do seu negógio. Gere quantos processos forem necessários para sua empresa trabalhar na máxima eficiência com uma prestação de serviço com qualidade para os seus clientes. Acompanhe cada processo para saber em status se encontra e cobre de forma mais assertiva o rendimento da sua equipe.</p>
                    </span>

                    <span>
                        <img src={comercialImg} alt = 'leads e comercial controladas pelo sistema gesttor' title='lead e comeercial controladas pelo sistema gesttor'/>
                        <p><b>Comercial</b></p>
                        <p>Cadastre suas leads diretamente no sistema. Gerencie as oportunidades de negócios apontando os motivos de perdas ou negócios fechados. Com o módulo do comercial você ainda pode:</p>
                        <li>Integração de Leads para o seu site</li>
                    </span>

                    <span>
                        <img src={financeiroImg} alt = 'finasças controladas pelo sistema gesttor' title='finanças controladas pelo sistema gesttor'/>
                        <p><b>Financeiro</b></p>
                        <p>Controle e gerencie as finanças sua empresa. Gere relatórios com os resultados operacionais e financeiros da sua empresa. Entenda a real situação financeira e de lucratividade do seu negócio. Com todos esses dados será mais fácil tomar as melhores descisões estratégicas para o futuro da sua empresa. Com o módulo financeiro você pode controlar:</p>
                        <li>Contas a pagar e receber</li>
                        <li>Conciliação bancária</li>
                    </span>

                    <span>
                        <img src={relatoriosImg} alt = 'relatórios gerados no sistema gesttor' title='relatórios gerados no sistema gesttor'/>
                        <p><b>Relatórios</b></p>
                        <p>Consulte as informações operacionais e financeiras resultantes das atividades do seu negócio. Gerencie a sua empresa com mais informações estratégicas e gerenciais tudo isso para favorecer as estratégias geradas para o crescimento. Com o módulo de relatórios você pode conferir:</p>
                        <li>O fluxo de caixa da sua empresa</li>
                        <li>A avaliação média dos atendimentos</li>
                        <li>A produtividade da sua equipe</li>
                    </span>
                </section>
            </section>
        </main>
    )
}

export default Gesttor