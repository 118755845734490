import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GesttorPage from "../../components/Gesttor";

function Gesttor () {

    return (
        <>
            <Header />
            <GesttorPage />
            <Footer />
        </>
    )
}

export default Gesttor;
