import { FormEvent, useContext, useState } from 'react'
import { modalCategoriaPergunta } from '../../interfaces/bancoTalentos';
import { Button, Loader } from '../styles'
import './modalDisc.css'
import { ModalEstadoContext } from '../../contexts/ModalEstado';
import Notificacoes from '../Notificacoes';
import { adicionarRespostas } from '../../services/bancoTalentos';
import { criptografarDado } from '../../helpers';

function ModalDisc({ perguntasDisc, idAvaliacao }: modalCategoriaPergunta) {
  const { exibirModal } = useContext(ModalEstadoContext)

  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(false)
  const [respostasAdicionadas, setRespostasAdicionadas] = useState(false)

  const [respostas, setRespostas] = useState<{
    [perguntaId: number]: {
      [alternativa: string]: string;
    }
  }>({})

  const handleChange = (perguntaId: number, alternativa: string, valor: string) => {
    setRespostas((prevRespostas) => ({
      ...prevRespostas,
      [perguntaId]: {
        ...prevRespostas[perguntaId],
        [alternativa]: valor
      }
    }));
  }

  const desativarOption = (perguntaId: number, valor: string) => {
    const respostasPergunta = respostas[perguntaId] || {}
    return Object.values(respostasPergunta).includes(valor)
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const respostaArray = Object.entries(respostas).map(([perguntaId, { a, b, c, d }]) => ({
      a: Number(a),
      b: Number(b),
      c: Number(c),
      d: Number(d),
      idPergunta: Number(perguntaId)
    }));

    const payload = { respostas: respostaArray }

    try {
      if (idAvaliacao) {
        setProcessando(true)

        const idAvaliacaoCriptografia = criptografarDado(idAvaliacao.toString())

        await adicionarRespostas(idAvaliacaoCriptografia, payload)

        setRespostasAdicionadas(true)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      setErro(true)
      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  };

  return (
    <div className="fundoModal">
      {erro && <Notificacoes cor='erro' texto='Falha ao Processar a Solicitação' />}
      {respostasAdicionadas && <Notificacoes cor='sucesso' texto='Avaliação Disc realizada com sucesso.' />}
      <section className="containerModalCentro">
        <form action="" onSubmit={handleSubmit}>
          {perguntasDisc.map((pergunta, index) =>
            <section key={pergunta.id}>
              <section>
                <span>
                  <label htmlFor="">
                    <strong>{index + 1}. {pergunta.pergunta}</strong>
                  </label>
                </span>
              </section>
              <section className='alternativasContainer'>
                <span className='alternativa'>
                  {pergunta.discAlternativas[0].a}
                  <select name="" defaultValue="" onChange={(e) => handleChange(pergunta.id, "a", e.target.value)} required>
                    <option value=""></option>
                    <option value="1" disabled={desativarOption(pergunta.id, "1")}>1</option>
                    <option value="2" disabled={desativarOption(pergunta.id, "2")}>2</option>
                    <option value="3" disabled={desativarOption(pergunta.id, "3")}>3</option>
                    <option value="4" disabled={desativarOption(pergunta.id, "4")}>4</option>
                  </select>
                </span>

                <span className='alternativa'>
                  {pergunta.discAlternativas[0].b}
                  <select name="" defaultValue="" onChange={(e) => handleChange(pergunta.id, "b", e.target.value)} required>
                    <option value=""></option>
                    <option value="1" disabled={desativarOption(pergunta.id, "1")}>1</option>
                    <option value="2" disabled={desativarOption(pergunta.id, "2")}>2</option>
                    <option value="3" disabled={desativarOption(pergunta.id, "3")}>3</option>
                    <option value="4" disabled={desativarOption(pergunta.id, "4")}>4</option>
                  </select>
                </span>

                <span className='alternativa'>
                  {pergunta.discAlternativas[0].c}
                  <select name="" defaultValue="" onChange={(e) => handleChange(pergunta.id, "c", e.target.value)} required>
                    <option value=""></option>
                    <option value="1" disabled={desativarOption(pergunta.id, "1")}>1</option>
                    <option value="2" disabled={desativarOption(pergunta.id, "2")}>2</option>
                    <option value="3" disabled={desativarOption(pergunta.id, "3")}>3</option>
                    <option value="4" disabled={desativarOption(pergunta.id, "4")}>4</option>
                  </select>
                </span>

                <span className='alternativa'>
                  {pergunta.discAlternativas[0].d}
                  <select name="" defaultValue="" onChange={(e) => handleChange(pergunta.id, "d", e.target.value)} required>
                    <option value=""></option>
                    <option value="1" disabled={desativarOption(pergunta.id, "1")}>1</option>
                    <option value="2" disabled={desativarOption(pergunta.id, "2")}>2</option>
                    <option value="3" disabled={desativarOption(pergunta.id, "3")}>3</option>
                    <option value="4" disabled={desativarOption(pergunta.id, "4")}>4</option>
                  </select>
                </span>
              </section>
              <hr />
            </section>)}
          <div className="botoesContainer">
            <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
            <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
              {!processando && 'Salvar'}
              {processando && <Loader isLoading={processando} />}
            </Button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default ModalDisc