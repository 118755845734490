import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import Login from '../Auth'
import exibirmenu from '../../assets/svg/menu.svg'
import fecharMenu from '../../assets/svg/fecharMenu.svg'
import logoHg from '../../assets/img/logoBranca.png'
import Link from '../Menu'
import './header.css'

function Header () {

    const navigate = useNavigate()

    const [menuOpcoes, setMenuOpcoes] = useState(false)
    const [exibirMenuIcone, setexibirMenuIcone] = useState (true)
    const [fecharMenuIcone, setFecharMenuIcone] = useState(false)

    const { modal, exibirModal } = useContext(ModalEstadoContext)
    
    const exibirMenu = () => {
        setMenuOpcoes (!menuOpcoes)
        setFecharMenuIcone (!fecharMenuIcone)
        setexibirMenuIcone (!exibirMenuIcone)
    }

    const home = () => {
        
        return navigate('/')
    }
    
    return (
        <header>
            <section id = 'contienerCabecalho'>
                <span>
                    <img id = 'logoHg' src = {logoHg} alt = 'Hg Consultoria' onClick={() => home()}/>
                </span>

                <span className = {menuOpcoes === true ? 'exibirSubMenuOpcoes' : 'ocultarSubMenuOpcoes'}>
                    <nav>
                        <Link texto = 'Sistema Gesttor' redirect = '/gesttor'/>
                        <Link texto = 'Sistema LinkPro' redirect = '/linkpro'/>
                        <Link texto = 'Portal do Candidato' redirect='/portal-auth#site-hg'/>
                        <Link texto = 'Contato' redirect = '/contato'/>
                    </nav>
                </span>
                {fecharMenuIcone && <img id='menuOpcoes' src={fecharMenu} alt='menu de opções hg' onClick={exibirMenu}/>}
                {exibirMenuIcone && <img id='menuOpcoes' src={exibirmenu} alt='menu de opções hg' onClick={exibirMenu}/>}
                {modal.nome === 'portalCandidatoAuth' && <Login/>}
            </section>
        </header>
    )
}

export default Header