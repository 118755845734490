import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setExibirModalQrCode } from '../../store/modules/modalQrCode'
import { RootStore } from '../../store'
import { cobrancasEmAbertoPayloadResposta } from '../../interfaces/cobrancas'
import { cobrancas } from '../../services/cobrancas'
import Notificacoes from '../Notificacoes'
import ModalQrCode from '../ModalQrCode'
import DivSituacao from '../DivSituacao'
import CarregandoCirculo from '../CarregandoCirculo'
import atendimentoIcone from '../../assets/img/atendimento.png'
import semCobrancasImg from '../../assets/img/confirmacao.png'
import pixImg from '../../assets/svg/pix.svg'
import './cobranca.css'

function Cobranca () {

    const atualizarDados = useSelector((store: RootStore) => store.atualizarDados)
    const modalQrCode = useSelector ((store: RootStore) => store.modalQrCode)
    const { idCliente } = useParams()
    const dispatch = useDispatch()

    const [qrcode, setQrCode] = useState (false)
    const [listaCobrancas, setListaCobrancas] = useState<cobrancasEmAbertoPayloadResposta[]> ([])
    const [processando, setProcessando] = useState(true)
    const [semCobranca, setSemCobranca] = useState(false)
    const [erro, setErro] = useState(false)

    function exibirModalQrCode (idReceita: number) {
        dispatch (setExibirModalQrCode ({
            idReceita
        }))
    }

    useEffect (() => {
        setQrCode(modalQrCode.exibir)
    }, [modalQrCode, qrcode])

    useEffect (() => {
        document.title = 'Cobranças'
    }, [])

    useEffect (() => {
        
        const listaCobrancas = async () => {

            if (idCliente === undefined) {
                return
            }

            try {

                const resposta = await cobrancas(idCliente)

                if (resposta.status === 200) {
                    setProcessando(false)
                    setListaCobrancas (resposta.data)
                }

                if (resposta.data === null) {
                    setSemCobranca (true)
                }
                
            } catch (error) {
                setProcessando(false)
                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 5000)
                
            }
        }

        listaCobrancas()

    }, [idCliente, atualizarDados])
    
    return (
        <div id = 'paginaCobranca'>
            {qrcode && <ModalQrCode />}
            {processando && <CarregandoCirculo />}
            {erro && <Notificacoes cor='erro' titulo='Tente Novamente. ' texto='Ocorreu um erro ao processar a sua solicitação 😥'/>}
            <div id='conteudoContienerCobranca'>
                <span id='cabecalhoMensagemAtendimento'>
                    <img id='atencimentoIcone' src={atendimentoIcone} alt = '' />
                    <p>Olá <b>{listaCobrancas[0]?.lancamento.cadastro.nomeFantasia.toUpperCase()}</b>! <br /> Ao lado você encontra o resumo das cobranças que estão <b>PENDENTES DE PAGAMENTO</b> no seu cadastro. Gere o <b>QrCode</b> para efetuar o pagamento.</p>
                </span>
                <section id = 'listaCobrancasContiener'>
                    <h1>Cobranças em Aberto</h1>
                    <table id = 'tblistaCobrancas'>
                        <thead>
                            <tr>
                                <th align='left'>Descrição</th>
                                <th align='left'>Valor</th>
                                <th align='center'>Vencimento</th>
                                <th align='center' className='situacaoCobranca'>Situação</th>
                                <th>Pagar com</th>
                            </tr>
                        </thead>
                        {!processando && <tbody>
                            {listaCobrancas?.map((cobranca) => (
                                <tr key={cobranca.id}>
                                    <td>{cobranca.lancamento.descricao.toUpperCase()}</td>
                                    <td align='left'>{Number(cobranca.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                    <td align='center'>{`${cobranca.dataCobranca.slice(8,10)}/${cobranca.dataCobranca.slice(5,7)}/${cobranca.dataCobranca.slice(0,4)}`}</td>
                                    <td align='center' className='situacaoCobranca'>
                                        <DivSituacao texto={cobranca.lancamento.situacao.toUpperCase()} cor={cobranca.lancamento.situacao === "Aguardando Recebimento" ? 'aberta' : 'vencida'} />
                                    </td>
                                    <td align='center' className='pagarPix'>
                                        <img src={pixImg} alt = '' onClick={() => exibirModalQrCode(cobranca.id)}/>
                                    </td>
                                </tr>                               
                            ))}
                        </tbody>}
                    </table>
                    {semCobranca && <section id = 'semCobranca'> 
                        <img src={semCobrancasImg} alt='' />
                        <p>Parabéns, você pagou todas as cobranças do <b>Hg</b>! 😉</p>
                    </section>}
                </section>
            </div>
        </div>
    )
}

export default Cobranca