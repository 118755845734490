import { useEffect } from 'react'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PortalAuthCandidato from "../../components/PortalAuth";

function PortalAuth () {

    useEffect(() => {

        document.title = 'Portal do Candidato | Login'

    }, [])

    return (
        <>
            <Header />
            <PortalAuthCandidato/>
            <Footer />
        </>
    )
}

export default PortalAuth