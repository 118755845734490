import { Routes as WrapperRoutes, Route, BrowserRouter } from 'react-router-dom'
import ComponentePrivado from '../middlewares/componentePrivado'
import PoliticaPrivacidade from '../pages/PoliticaPrivacidade'
import TermosDeUso from '../pages/TermosUso'
import Cobranca from '../pages/Cobranca'
import Contato from '../pages/Contato'
import Gesttor from '../pages/Gesttor'
import LinkPro from '../pages/LinkPro'
import Home from '../pages/Home'
import Nps from '../pages/Nps'
import PortalAuth from '../pages/PortalAuth'
import PortalCandidato from '../pages/PortalCandidato'

function Routes () {
    return (
        <BrowserRouter>
            <WrapperRoutes>
                <Route path='*' element = { <Home /> } />
                <Route path='/' element = { <Home /> } />
                <Route path='/contato' element = { <Contato />} />
                <Route path='/gesttor' element= {<Gesttor/>} />
                <Route path='/linkpro' element= {<LinkPro/>} />
                <Route path='/cobranca/:idCliente' element = { <Cobranca />} />
                <Route path='/nps/:organizacao/:tarefa/:responsavel' element = {<Nps />}/>
                <Route path='/politicaprivacidade' element = {<PoliticaPrivacidade />} />
                <Route path='/termosuso' element={<TermosDeUso />} />
                <Route path='/portal-auth' element={<PortalAuth/>}/>
                <Route path='/portal-candidato' element={<ComponentePrivado><PortalCandidato/></ComponentePrivado>}/>
            </WrapperRoutes>
        </BrowserRouter>
    )
}

export default Routes