import { useEffect } from 'react'
import linkproImg from '../../assets/img/sistemaLinkpro.png'
import tarefasImg from '../../assets/img/tarefas.png'
import processosImg from '../../assets/img/processos.png'
import comercialImg from '../../assets/img/comercial.png'
import financeiroImg from '../../assets/img/financeiro.png'
import relatoriosImg from '../../assets/img/relatorios.png'
import './linkpro.css'

function LinkPro () {

    useEffect (() => {
        document.title = 'Sistema LinkPro | Hg'
    }, [])

    return (
        <main>
            <section id='introducaoHomeContenier'>
                <span>
                    <span id='chamacaoAcaoTitulo'>
                        <h1>Deixe o dia a dia da sua empresa mais rápido e EFICIENTE com o LinkPro</h1>
                        <p>Conhece a <b>Sistema LinkPro</b>, agende uma apresentação <b>GRATUITA</b> de nossa ferramenta e descubra como podemos contribuir para a gestão do seu negócio</p>
                    </span>
                    <span id = 'chamadaAcao'>
                        <a id='btnAcaoServico' href="https://wa.me/message/YYTHX6J2OKZ3O1" target='_blanck'>Fale Conosco</a>
                    </span>
                </span>
                <span>
                    <img id='sistemaLinkPro'  src={linkproImg} alt = 'sistema gesttor linkpro do Hg Consultoria' />
                </span>
            </section>

            <section id = 'apresentacaoSistemaGesttor'>
                <span>
                    <iframe 
                        src="https://www.youtube.com/embed/e4w3J8L_g_k?controls=0" 
                        title="Apresentação Sistema LinkPro"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                    </iframe>
                </span>
            </section>

            <section id = 'funcionalidadesLinkPro'>
                <section>
                    <span>
                        <p><b>Conheça o LinkPro</b></p>
                        <p> O <strong>LinkPro</strong> é  sistema para facilitar o dia a dia da empresa com a emissão de notas fiscais, relatórios de vendas, balanço patrimonial e gestão do estoque. Conta ainda com uma versão do sistema on-line. Sistema <strong>prático</strong>, simples de usar e com diversos relatórios para sua tomada de decisão. Agende agora mesmo uma demonstração gratuita e conheça essa poderosa ferramenta para sua empresa. Ideal para:</p>
                        <li>Bares e Restaurantes</li>
                        <li>Lojas de Roupas e Acessórios</li>
                        <li>Lojja de Variedades e Acessórios</li>
                        <li>Varejo em Geral</li>
                    </span>

                    <span>
                        <p><b>Um Sistema Completo</b></p>
                        <li>Não utiliza o conceito de módulos;</li>
                        <li> Todos os recursos já desenvolvidos, para o Link, estão disponíveispara todos os usuários;</li>
                        <li>Todas as atualizações já estão inclusas na sua mensalidade;</li>
                        <li>Se encaixa nos mais diversos segmentos do varejo, de serviços e até bares e restaurantes;</li>
                        <li>Uma licença do sistema Link consiste em 1 (um) computador habilitado pelo período de 1 (um) mês.</li>
                    </span>

                    <span>
                        <p><b>Diversos Recursos na Tela do Caixa</b></p>
                        <li>Consulta de variações e busca de produtos do estoque das outras lojas;</li>
                        <li>Busca de pré-vendas, pedido condicional e devolução;</li>
                        <li>Cadastro de clientes e autorizados a comprar em seu crediário;</li>
                        <li>Diversas condições e formas de pagamento como Boleto, Cartão, Cheque, Crediário e Dinheiro e com mais uma forma de pagamento;</li>
                        <li>Pagamento por Pix;</li>
                        <li>Impressão de documentos fiscais e relatórios. Suprimento, sangria, consulta de movimentação, emissão de extrato financeiro, fechamento e muito mais.</li>
                    </span>

                    <span>
                        <p><b>Bares | Restaurantes | Lanchonetes</b></p>
                        <li>Recurso do LinkPro ideal para o segmento que não para de crescer, o de alimentação.Controle de pedidos por mesas ou comandas; </li>
                        <li>Com o "Multisabores" é possível acrescentar vários sabores em um produto;</li>
                        <li>Opção de criar "Combos" com 2 ou mais produtos. Ex: Lanche + bebida;</li>
                        <li>Envio de pedidos para pontos de impressão. Ex: para a cozinha;</li>
                        <li>Divisão do pagamento por cliente ou pedido, além de controle de taxas. Ex: Comissão. Controle de Delivery e muito mais.</li>
                    </span>

                    <span>
                        <p><b>Soluções em Pagamento</b></p>
                        <p>Temos integrações direta com as maquinihas smart da PagSeguro, Stone, Cielo e Vero. Essas integrações facilitam e automatizam o processo de recebimento por cartão no seu negócio, evitando erros e retrabalhos. Nessa integração vocêe pode:</p>
                        <li>Gerar ordem de pagamento e receber pelo cartão diretamente na maquiniha de forma automatizada;</li>
                        <li>Entregadores consultam em tempo real as ordem de pagamento pendente;</li>
                    </span>

                    <span>
                        <p><b>Loja Virtual Integrada ao LinkPro</b></p>
                        <li>Disponibilize seus produtos de maneira online na sua loja Tray. Consultar condições de integração</li>
                        <li>Sincronize o cadastro dos produtos com "Grupo", "Subgrupo" e "Marca";</li>
                        <li>Sincronize imagens dos produtos e defina a imagem principal;</li>
                        <li>Defina se seu produtos é um Infoproduto (virtual) ou físico;</li>
                        <li>Emita e transmita a nota fiscal com base na venda;</li>
                        <li>Lançe no financeiro do LinkPro, os pagamentos de pedidos realizados pela sua loja virtual, tudo isso de forma integrada.</li>
                    </span>
                </section>
            </section>
        </main>
        
    )
}

export default LinkPro