import { createContext, useState } from "react";
import { propsContext } from "../../interfaces/propsContext"
import { propsProvider } from "../../interfaces/propsProvider"

const ModalEstadoContext = createContext<propsContext>({
    modal: { nome: '' },
    exibirModal: () => {}
})

const ModalEstadoProvider = ({ children }: propsProvider) => {

    const [modal, setModal] = useState({
        nome: ''
    })

    const exibirModal = (nome: string ) => setModal({ nome: nome })


    return (
        <ModalEstadoContext.Provider value={{ modal, exibirModal }}>
            {children}
        </ModalEstadoContext.Provider>
    )
}

export { ModalEstadoProvider, ModalEstadoContext }