import introducaoHomeImg from '../../assets/img/analise.png'
import './introducaohome.css'

function IntroducaoHome() {

    return (
        <section id='containerIntroducaoHome'>  
            <section id='introducaoHomeContent'>
                <span id='ladoEsquerdoContent'>
                    <span id='textosIntroducaoHome'>
                        <p>Olá, somos o</p>
                        <h1>Hg Consultoria</h1>
                        <p>especialista em desenvolver soluções para o seu negócio crescer mais</p>
                    </span>
                    <span>
                        <a id='btnChamadaHome' href="https://wa.me/5582982244390" target='_blanck'>Vamos Conversar sobre a sua Ideia</a>
                    </span>
                </span>
                <span id='imgIntroducaoHome'>
                    <img src={introducaoHomeImg} alt='seja bem vindo ao Hg Consultoria. Assessoria especializada desenvolver a sua empresa'/>
                </span>
            </section>
        </section>
    )
}

export default IntroducaoHome