import { FormEvent, useContext, useEffect, useState } from 'react'
import { adicionarAreaInteresse, adicionarCurso, adicionarExperiencia, adicionarFormacao, atualizarCurriculo, atualizarCurso, atualizarExperiencia, atualizarFormacao, atualizarFotoPerfil, dadosCandidato, deletarFormacao, excluirCurso, excluirExperiencia, excluirInteresseArea, adicionarRegimeInteresse, excluirRegimeInteresse, listaPerguntasDisc, criarAvaliacao } from '../../services/bancoTalentos';
import { listarDadosCandidato, formacaoAcademicaProps, cursoProps, experienciaProps, discPergunta } from '../../interfaces/bancoTalentos';
import { Button, Loader } from '../styles';
import Notificacoes from '../Notificacoes';
import fotoPerfil from "../../assets/img/fotoPerfil.png";
import editarIcon from '../../assets/img/editar.png'
import editarIconBranco from '../../assets/img/iconBrancoEditar.png'
import adicionarIcon from '../../assets/img/botao-adicionar.png'
import removerIcon from '../../assets/img/botao-apagar.png'
import { cepMascara, telefoneMascara } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import './curriculo.css'
import ModalDisc from '../ModalDisc';
import { ModalEstadoContext } from '../../contexts/ModalEstado';

function Curriculo() {

  const navigate = useNavigate()
  const { modal, exibirModal } = useContext(ModalEstadoContext)

  const [candidato, setCandidato] = useState<listarDadosCandidato>()
  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(false)
  const [dadosSobreMim, setDadosSobreMim] = useState("")
  const [visivel, setVisivel] = useState(false)

  const [nomeCompleto, setNomeCompleto] = useState(candidato?.nomeCompleto || undefined)
  const [telefone, setTelefone] = useState(candidato?.telefone || "")
  const [profissao, setProfissao] = useState()
  const [dataNascimento, setDataNascimento] = useState("")
  const [rua, setRua] = useState()
  const [numero, setNumero] = useState()
  const [bairro, setBairro] = useState()
  const [complemento, setComplemento] = useState()
  const [estado, setEstado] = useState()
  const [cidade, setCidade] = useState()
  const [uf, setUf] = useState()
  const [cep, setCep] = useState("")
  const [notificacaoEmail, setNotificacaoEmail] = useState(false)
  const [notificacaoWhatsapp, setNotificacaoWhatsapp] = useState(false)

  const [formacaoAcademica, setFormacaoAcademica] = useState<formacaoAcademicaProps[]>([])
  const [formacaoAcademicaSelecionado, setFormacaoAcademicaSelecionado] = useState<formacaoAcademicaProps>();
  const [erroData, setErroData] = useState(false)

  const [cursoComplementar, setCursoComplementar] = useState<cursoProps[]>([])

  const [cursoComplementarSelecionado, setCursoComplementarSelecionado] = useState<cursoProps>()

  const [experienciaProfissional, setExperienciaProfissional] = useState<experienciaProps[]>([])
  const [experienciaProfissionalSelecionado, setExperienciaProfissionalSelecionado] = useState<experienciaProps>()

  const [perguntasDisc, setPerguntasDisc] = useState<discPergunta[]>([])
  const [avaliacaoSelecionado, setAvaliacaoSelecionado] = useState<number>()

  const [descricaoArea, setDescricaoArea] = useState("")

  const [cargo, setCargo] = useState("")
  const [nomeEmpresa, setNomeEmpresa] = useState("")
  const [tipoContrato, setTipoContrato] = useState("")
  const [cargoTemporario, setCargoTemporario] = useState("")
  const [cargoLideranca, setCargoLideranca] = useState("")
  const [principaisAtribuicoes, setPrincipaisAtribuicoes] = useState("")
  const [idExperiencia, setIdExperiencia] = useState("")

  const [nomeCurso, setNomeCurso] = useState("")
  const [nivelFormacao, setNivelFormacao] = useState("")
  const [situacaoCurso, setSituacaoCurso] = useState("")
  const [dataInicio, setDataInicio] = useState("")
  const [dataFinal, setDataFinal] = useState("")
  const [instituicaoEnsino, setInstituicaoEnsino] = useState("")
  const [idFormacao, setIdFormacao] = useState("")
  const [cargaHoraria, setCargaHoraria] = useState(0)

  const [curriculoAtualizado, setCurriculoAtualizado] = useState(false)

  const [novoAvatar, setNovoAvatar] = useState<File>()
  const [avatarAtual, setAvatarAtual] = useState("")
  const [avatarPreview, setAvatarPreview] = useState("");
  const [erroAtualizarAvatar, setErroAtualizarAvatar] = useState(false)

  const [campoTelefone, setCampoTelefone] = useState(false)
  const [campoCep, setCampoCep] = useState(false)
  const [campoNomeCompleto, setCampoNomeCompleto] = useState(false)

  const [valorExistente, setValorExistente] = useState(false)

  const [btnSalvarNotificacao, setBtnSalvarNotificacao] = useState(false)

  const submitDadosModal = (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    atualizarDados(e)
    exibirModal("")
  }

  const adicionarFormacaoAcademica = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload = {
        curso: nomeCurso,
        nivel: nivelFormacao,
        instituicaoEnsino,
        dataInicio,
        dataTermino: dataFinal || null,
        situacaoCurso
      }

      const resposta = await adicionarFormacao(payload)

      if (resposta.status === 201) {
        setCurriculoAtualizado(true)
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErro(true)
        setErroData(true)
      }

      return setTimeout(() => {
        setErro(false)
        setErroData(false)
      }, 5000)
    }
  }

  const editarFormacaoAcademica = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload = {
        curso: nomeCurso,
        nivel: nivelFormacao,
        instituicaoEnsino,
        dataInicio,
        dataTermino: dataFinal,
        situacaoCurso
      }

      const payloadFiltrado = filtroPayload(payload)

      const resposta = await atualizarFormacao(payloadFiltrado, idFormacao)

      if (resposta.status === 200) {
        setCurriculoAtualizado(true)

        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErro(true)
        setErroData(true)
      }

      return setTimeout(() => {
        setErro(false)
        setErroData(false)
      }, 5000)
    }
  }

  const deletarFormacaoAcademica = async (idFormacao: string) => {
    setProcessando(true);

    try {
      const resposta = await deletarFormacao(idFormacao)

      if (resposta.status === 204) {
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 500) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const adicionarCursoComplementar = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload = {
        curso: nomeCurso,
        instituicaoEnsino,
        dataInicio,
        dataTermino: dataFinal || null,
        situacaoCurso,
        cargaHoraria
      }

      const resposta = await adicionarCurso(payload)

      if (resposta.status === 201) {
        setCurriculoAtualizado(true)
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErroData(true)
      }

      return setTimeout(() => {
        setErroData(false)
      }, 5000)
    }
  }

  const editarCursoComplementar = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload = {
        curso: nomeCurso,
        instituicaoEnsino,
        dataInicio,
        dataTermino: dataFinal,
        cargaHoraria,
        situacaoCurso
      }

      const payloadFiltrado = filtroPayload(payload)

      const resposta = await atualizarCurso(payloadFiltrado, idFormacao)

      if (resposta.status === 200) {
        setCurriculoAtualizado(true)

        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErroData(true)
      }

      return setTimeout(() => {
        setErroData(false)
      }, 5000)
    }
  }

  const deletarCursoComplementar = async (idCurso: string) => {
    setProcessando(true)
    try {
      const resposta = await excluirCurso(idCurso)

      if (resposta) {
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 500) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const adicionarExperienciaProfissional = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload = {
        cargo,
        dataTermino: dataFinal || null,
        dataInicio,
        nomeEmpresa,
        regimeContratacao: tipoContrato,
        cargoLideranca: cargoLideranca === "1" ? true : false,
        tempoDeterminado: cargoTemporario === "1" ? true : false,
        principaisAtribuicoes
      }

      const resposta = await adicionarExperiencia(payload)

      if (resposta.status === 201) {
        setCurriculoAtualizado(true)
        setProcessando(false)

        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErroData(true)
      }

      return setTimeout(() => {
        setErroData(false)
      }, 5000)
    }

  }

  const editarExperienciaProfissional = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload = {
        cargo,
        dataTermino: dataFinal,
        dataInicio,
        nomeEmpresa,
        regimeContratacao: tipoContrato,
        cargoLideranca: cargoLideranca === "1",
        tempoDeterminado: cargoTemporario === "1",
        principaisAtribuicoes
      }

      const payloadFiltrado = filtroPayload(payload)

      const resposta = await atualizarExperiencia(payloadFiltrado, idExperiencia)

      if (resposta.status === 200) {
        setProcessando(false)
        setCurriculoAtualizado(true)

        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErroData(true)
      }

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const deletarExperienciaProfissional = async (idCurso: number) => {
    setProcessando(true)
    try {
      const resposta = await excluirExperiencia(idCurso)

      if (resposta) {
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 500) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const abrirModalFormacao = async (formacao: any) => {
    exibirModal("modalEditarFormacao")
    setFormacaoAcademicaSelecionado(formacao)
    setNivelFormacao(formacao.nivel)
    setSituacaoCurso(formacao.situacaoCurso)
    setNomeCurso(formacao.curso)
    setDataInicio(formacao.dataInicio.toString())
    setDataFinal(formacao.dataTermino && formacao.dataTermino.toString())
    setInstituicaoEnsino(formacao.instituicaoEnsino)
    setIdFormacao(formacao.id)
  }

  const abrirModalCurso = async (curso: any) => {
    exibirModal("modalEditarCurso");
    setCursoComplementarSelecionado(curso)
    setSituacaoCurso(curso.situacaoCurso)
    setNomeCurso(curso.curso)
    setDataInicio(curso.dataInicio.toString())
    setDataFinal(curso.dataTermino && curso.dataTermino.toString())
    setInstituicaoEnsino(curso.instituicaoEnsino)
    setIdFormacao(curso.id)
    setCargaHoraria(curso.cargaHoraria)
  }

  const abrirModalExperiencia = async (experiencia: experienciaProps) => {
    exibirModal("modalEditarExperiencia")
    setIdExperiencia(experiencia.id.toString())
    setExperienciaProfissionalSelecionado(experiencia)
    setDataInicio(experiencia.dataInicio.toString())
  }

  const adicionarInteresseRegime = async (e: FormEvent) => {
    e.preventDefault()

    try {
      const payload = {
        descricao: descricaoArea
      }

      const resposta = await adicionarRegimeInteresse(payload)

      if (resposta.status === 201) {
        setProcessando(false)

        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 400) {
        setErro(true)
      }

      if (error.response.status === 409) {
        setValorExistente(true)
      }

      return setTimeout(() => {
        setErro(false)
        setValorExistente(false)
      }, 5000)
    }
  }

  const adicionarInteresseArea = async (e: FormEvent) => {
    e.preventDefault()

    try {
      const payload = {
        descricao: descricaoArea
      }

      const resposta = await adicionarAreaInteresse(payload)

      if (resposta.status === 201) {
        setProcessando(false)
        setCurriculoAtualizado(true)

        return window.location.reload()
      }
    } catch (error: any) {
      if (error) {
        setProcessando(false);
        if (error.response.status === 400) {
          setErro(true)
        }

        if (error.response.status === 409) {
          setValorExistente(true)
        }

        return setTimeout(() => {
          setErro(false)
          setValorExistente(false)
        }, 5000)
      }
    }
  }

  const excluirAreaInteresse = async (idArea: number) => {
    setProcessando(true)
    try {
      const resposta = await excluirInteresseArea(idArea)

      if (resposta.status === 204) {
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 500) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const excluirInteresseRegime = async (idArea: number) => {
    setProcessando(true)
    try {
      const resposta = await excluirRegimeInteresse(idArea)

      if (resposta.status === 204) {
        setProcessando(false)
        return window.location.reload()
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 500) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const atualizarDados = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const situacaoCadastral = await verificaSituacaoCompleto()

      const payload = {
        sobreMim: dadosSobreMim,
        nomeCompleto,
        telefone,
        profissao,
        dataNascimento,
        rua,
        numero: numero || null,
        bairro,
        complemento,
        estado,
        cidade,
        uf,
        cep,
        notificacaoEmail,
        notificacaoWhatsapp,
        situacaoCadastral
      }

      const payloadFiltrado = filtroPayload(payload)
      const resposta = await atualizarCurriculo(payloadFiltrado)

      if (resposta.status === 200) {
        setCurriculoAtualizado(true)

        return window.location.reload()
      }
    }


    catch (error: any) {
      setProcessando(false);

      if (error.response.status === 400) setErro(true)
      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const listarDadosCandidato = async () => {

    try {

      const resposta = await dadosCandidato()
      setCandidato(resposta.data)
      setDadosSobreMim(resposta.data.sobreMim)
      formatarDataNascimento(resposta.data.dataNascimento)
      setEstado(resposta.data.estado)
      setCidade(resposta.data.cidade)
      setUf(resposta.data.uf)
      setRua(resposta.data.rua)
      setBairro(resposta.data.bairro)
      setNotificacaoEmail(resposta.data.autorizaEmail)
      setNotificacaoWhatsapp(resposta.data.autorizaWhatsapp)
      setFormacaoAcademica(resposta.data.formacaoAcademicas)
      setCursoComplementar(resposta.data.cursosComplementares)
      setExperienciaProfissional(resposta.data.experienciaProfissionals)
    } catch (error) {

      setProcessando(false)
      setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const handleChange = (e: any, setState: (arg: any) => void) => {
    setState(e.target.value)
  }

  const mudarVisibilidadeModal = async () => {
    if (telefone) validarTelefone(telefone);
    if (cep) validarCep(cep);
    if (nomeCompleto) validarNomeCompleto(nomeCompleto);

    setCampoTelefone(false)
    setVisivel(!visivel)
  }

  const formatarDataNascimento = async (dataNascimento: string) => {
    if (dataNascimento) {
      const data = dataNascimento.toString().split("-")

      const anoNascimento = data[0]
      const mesNascimento = data[1]
      const diaNascimento = data[2]

      setDataNascimento(`${anoNascimento}-${mesNascimento}-${diaNascimento}`)
    }
  }

  const validarTelefone = (telefone: string) => {
    const regex = /^(\([0-9]{2}\))\s([0-9]{1})?\.([0-9]{4})\s-\s([0-9]{4})/
    const telefoneValidado = regex.test(telefone)

    if (!telefoneValidado) {
      setCampoTelefone(true)
      return setTimeout(() => {
        setCampoTelefone(false)
      }, 5000)
    }
  }

  const validarCep = (cep: string) => {
    const regex = /^([0-9]{5}\s-\s[0-9]{3})/
    const cepValidado = regex.test(cep)

    if (!cepValidado) {
      setCampoCep(true)
      return setTimeout(() => {
        setCampoCep(false)
      }, 5000)
    }
  }

  const validarNomeCompleto = (nomeCompleto: string) => {
    const regex = /[A-Za-z]+( [A-Za-z]+)+$/
    const nomeValidado = regex.test(nomeCompleto)

    if (!nomeValidado) {
      setCampoNomeCompleto(true)
      return setTimeout(() => {
        setCampoNomeCompleto(false)
      }, 5000)
    }
  }

  const alterarFotoPerfil = (e: FormEvent) => {
    e.preventDefault()

    const target = e.target as HTMLInputElement;
    const novaFoto = target.files as FileList;

    if (novaFoto && novaFoto.length > 0) setNovoAvatar(novaFoto[0]);
  }

  const enviarFotoPerfil = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    if (!novoAvatar) {
      setErroAtualizarAvatar(true)
      setProcessando(false)

      return setTimeout(() => {
        setErroAtualizarAvatar(false)
      }, 5000)
    }

    const payload = {
      avatar: novoAvatar
    }

    try {

      const resposta = await atualizarFotoPerfil(payload)

      if (resposta.status === 200) {
        localStorage.setItem("candidatoHgAvatar", `${resposta.data}`)
        setProcessando(false)
        exibirModal("")
        return window.location.reload()
      }

    } catch (error) {
      setProcessando(false)
      setErroAtualizarAvatar(true)
      exibirModal("")

      return setTimeout(() => {
        setErroAtualizarAvatar(false)
      }, 5000)
    }
  }

  const logout = () => {

    localStorage.removeItem('candidatoHgAvatar')
    document.cookie = 'authCandidato=; expires=true, 01 Jan 2024 00:00:00 GMT'

    return navigate('/portal-auth')
  }

  const handleChangeEstado = (e: any) => {
    const nomeEstado = e.target.value.split('-')
    setEstado(nomeEstado[0])
    setUf(nomeEstado[1])
  }

  const verificaSituacaoCompleto = async () => {
    if (dadosSobreMim && dataNascimento && avatarAtual && rua && bairro && cidade && estado) {
      return "Completo"
    } else {
      return "Incompleto"
    }
  }

  const filtroPayload = (payload: any) => {
    return Object.fromEntries(
      Object.entries(payload).filter(
        ([chave, valor]) => valor !== null && valor !== undefined && valor !== ""
      )
    )
  }

  const listarPerguntas = async () => {
    try {
      const resposta = await listaPerguntasDisc()

      if (resposta) {
        setProcessando(false);
        setPerguntasDisc(resposta.data)
      }
    } catch (error: any) {
      setProcessando(false);
      if (error.response.status === 500) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const abrirAvaliacaoDisc = async (idAvaliacaoSelecionado: number) => {
    exibirModal("modalAvaliacaoDisc")
    setAvaliacaoSelecionado(idAvaliacaoSelecionado)
  }

  const criarAvaliacaoDisc = async () => {

    try {
      const avaliacaoCriada = await criarAvaliacao()
      setAvaliacaoSelecionado(avaliacaoCriada.data.id)
      listarPerguntas()
      exibirModal("modalQuestoesDisc")
      if (!avaliacaoCriada) return setErro(true);
    } catch (error) {
      if (error) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const continuarAvaliacaoDisc = async () => {
    try {
      listarPerguntas()
      exibirModal("modalQuestoesDisc")
      if (!avaliacaoSelecionado) return setErro(true);
    } catch (error: any) {
      if (error) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  useEffect(() => {
    const fotoAvatarCandidato = () => {
      const candidatoAvatar = localStorage.getItem('candidatoHgAvatar')

      if (!candidatoAvatar) return setAvatarAtual(fotoPerfil)

      return setAvatarAtual(candidatoAvatar)
    }

    fotoAvatarCandidato()
  }, [])

  useEffect(() => {
    listarDadosCandidato()
  }, [])

  useEffect(() => {
    setTelefone(candidato?.telefone || "");
    setCep(candidato?.cep || "");
  }, [candidato])

  useEffect(() => {
    if (novoAvatar) {
      const avatarPreview = URL.createObjectURL(novoAvatar);
      setAvatarPreview(avatarPreview);
    } else {
      setAvatarPreview("");
    }
  }, [novoAvatar])

  useEffect(() => {
    if (candidato && candidato.autorizaEmail === notificacaoEmail && candidato.autorizaWhatsapp === notificacaoWhatsapp) {
      return setBtnSalvarNotificacao(false)
    }
    setBtnSalvarNotificacao(true)
  }, [candidato, notificacaoEmail, notificacaoWhatsapp])

  return (
    <section className='bodyCurriculo'>
      {erro && <Notificacoes cor='erro' texto='Falha ao Processar a Solicitação' />}
      {valorExistente && <Notificacoes cor='erro' texto='Não é possivel adicionar um valor já existente.' />}
      {campoTelefone && <Notificacoes cor='erro' titulo='Telefone no formato inválido.' texto="Numero de telefone inválido." />}
      {campoCep && <Notificacoes cor='erro' titulo='CEP no formato inválido.' texto="Cep inválido." />}
      {campoNomeCompleto && <Notificacoes cor='erro' titulo='Preenchimento Obrigatório' texto="O campo nome completo é inválido. Insira o Nome Completo." />}
      {erroAtualizarAvatar && <Notificacoes cor='erro' titulo='Foto de perfil.' texto="Não foi possivel atualizar sua foto de perfil." />}
      {erroData && <Notificacoes cor='erro' titulo='A Data de inicio ' texto="não pode ser maior que a data final." />}
      {curriculoAtualizado && <Notificacoes cor='sucesso' titulo='Curriculo Atualizado!' texto="Seu curriculo foi atualizado com sucesso." />}
      <section className='containerCurriculo flex-column'>
        <div className='div-header-foto'>
          <section className='headerCurriculo'>
            <span className='container-fotoPerfil left' onClick={() => exibirModal("modalAvatar")}>
              <div className='container-mudar-fotoPerfil'>
                <img src={candidato?.avatar ? avatarAtual : fotoPerfil} alt="" className='fotoPerfil' />
                <div className='overlayFotoPerfil'>
                  <span>Mudar Foto</span>
                </div>
              </div>
            </span>
            <span className='container-header flex-column'>
              <span className='nome-candidato'>
                <span>
                  <h2 className='nomeCompletoCandidato'>{candidato && candidato.nomeCompleto}</h2>
                  <label>{candidato && candidato.profissao}</label>
                </span>
                <img src={editarIconBranco} alt="" className='iconEditarModal' onClick={() => exibirModal("modalDadosPessoais")} />
              </span>
              <span className='area-contato'>
                <span>
                  <h3>Telefone:</h3>
                  <label>{candidato && candidato.telefone}</label>
                </span>
                <span>
                  <h3>E-mail</h3>
                  <label>{candidato && candidato.email}</label>
                </span>
              </span>
            </span>
          </section>
        </div>
        <section className='mainCurriculo flex-row'>
          <aside className='left hidden-responsive'>
            <hr />
            <span className='endereco'>
              <h3>Endereço</h3>
              <p>{(candidato && candidato.rua && candidato.numero) ? `${candidato.rua} ${candidato.numero}` : "Adicione um endereço"}
              </p>
              <p>{candidato && candidato.complemento}</p>
              <p>{candidato && candidato.bairro}</p>
              <p>{candidato && candidato.cep}</p>
              <p>{(candidato && candidato.cidade && candidato.estado) && `${candidato.cidade}/${candidato.estado}-${candidato.uf}`}</p>
            </span>
            {candidato && candidato.interesseAreas &&
              <span>
                <hr />
                <span className='container-editar'>
                  <h3>Áreas de Interesse</h3>
                  <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalArea")} />
                </span>
                {candidato.interesseAreas.map((area) => (
                  <span key={area.id}>
                    <label>{area.descricao}</label>
                    <br />
                  </span>
                ))}
                <hr />
              </span>
            }
            {candidato && candidato.interesseAreas &&
              <span>

                <span className='container-editar'>
                  <h3>Regimes de Interesse</h3>
                  <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalRegime")} />
                </span>
                {candidato.interesseRegimes.map((interesse) => (
                  <span key={interesse.id}>
                    <label>{interesse.descricao}</label>
                    <br />
                  </span>
                ))}
                <hr />
              </span>
            }
            <h3>Notificação</h3>
            <span className='notificacoes'>
              <input type="checkbox" name="autorizaEmail" checked={notificacaoEmail} onChange={(e) => setNotificacaoEmail(e.target.checked)} />
              <label htmlFor="autorizaEmail">Receber notificações por E-mails</label>
            </span>
            <span className='notificacoes'>
              <input type="checkbox" name="autorizaWhatsapp" checked={notificacaoWhatsapp} onChange={(e) => setNotificacaoWhatsapp(e.target.checked)} />
              <label htmlFor="autorizaWhatsapp">Receber notificações por Whatsapp</label>
            </span>
            <span>
              {btnSalvarNotificacao && <Button isLoading={processando} cor="continuarSucesso" type='button' isCancelar={false} onClick={(e) => atualizarDados(e)}>Salvar</Button>}
            </span>
            <hr />
            <span>
              {candidato && candidato.situacaoCadastral === "Incompleto" ?
                <label className="situacaoIncompleto">
                  <abbr title="O Sobre Mim, Data de Nascimento, Foto de Perfil e Endereço precisam ser preenchidos para completar o perfil.">
                    Perfil Incompleto
                  </abbr>
                </label>
                :
                <label className="situacaoCompleto">
                  Perfil Completo
                </label>
              }
            </span>
            <Button isLoading={processando} cor="cancelar" type='button' isCancelar={false} onClick={() => logout()}>Logout</Button>
          </aside>
          <div className='main-content-curriculo'>
            <section className='sessao-inf-usuario'>
              <div className='curriculoAbout'>
                <div className='container-editar'>
                  <h2>SOBRE MIM</h2>
                  <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalSobre")} />
                </div>
                <p>{dadosSobreMim || "Fale um pouco sobre você"}</p>
              </div>
            </section>
            <section className='sessao-inf-usuario'>
              <div className='curriculoAbout'>
                <div className='container-editar'>
                  <h2>FORMAÇÃO ACADÊMICA</h2>
                  <img src={adicionarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalAdicionarFormacao")} />
                </div>
                {formacaoAcademica && formacaoAcademica.length > 0 &&
                  formacaoAcademica.map((formacao) => {
                    return (
                      <section key={formacao.id}>
                        <hr />
                        <span className='between'>
                          <span>
                            <p><strong>{formacao.curso} - {formacao.nivel}</strong></p>
                            <p className='texto-opaco'>{formacao.instituicaoEnsino}</p>
                            <p className='texto-opaco'>
                              {(new Date(formacao.dataInicio).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(formacao.dataInicio).getFullYear().toString()}
                              {" - "}
                              {formacao.dataTermino ? (new Date(formacao.dataTermino).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(formacao.dataTermino).getFullYear().toString() : ""}
                            </p>
                            <p className='texto-opaco'>{formacao.situacaoCurso}</p>
                          </span>
                          <span className='icones'>
                            <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => abrirModalFormacao(formacao)} />
                            <img src={removerIcon} alt="" className='iconEditarModal' onClick={() => deletarFormacaoAcademica(formacao.id)} />
                          </span>
                        </span>
                      </section>
                    )
                  }
                  )
                }
              </div>
            </section>
            <section className='sessao-inf-usuario'>
              <div className='curriculoAbout'>
                <div className='container-editar'>
                  <h2>CURSOS COMPLEMENTARES</h2>
                  <img src={adicionarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalAdicionarCurso")} />
                </div>
                {cursoComplementar && cursoComplementar.length > 0 &&
                  cursoComplementar.map((curso) => {
                    return (
                      <section key={curso.id}>
                        <hr />
                        <span className='between'>
                          <span>
                            <p><strong>{curso.curso} - {curso.instituicaoEnsino}</strong></p>
                            <p className='texto-opaco'>
                              {(new Date(curso.dataInicio).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(curso.dataInicio).getFullYear().toString()}
                              {" - "}
                              {curso.dataTermino ? (new Date(curso.dataTermino).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(curso.dataTermino).getFullYear().toString() : ""}
                            </p>
                            <p className='texto-opaco'>{curso.situacaoCurso}</p>
                          </span>
                          <span className='icones'>
                            <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => abrirModalCurso(curso)} />
                            <img src={removerIcon} alt="" className='iconEditarModal' onClick={() => deletarCursoComplementar(curso.id)} />
                          </span>
                        </span>
                      </section>
                    )
                  }
                  )
                }
              </div>
            </section>
            <section className='sessao-inf-usuario'>
              <div className='curriculoAbout'>
                <div className='container-editar'>
                  <h2>EXPERIÊNCIA PROFISSIONAL</h2>
                  <img src={adicionarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalAdicionarExperiencia")} />
                </div>
                {
                  experienciaProfissional && experienciaProfissional.length > 0 && experienciaProfissional.map((experiencia) => (
                    <section key={experiencia.id}>
                      <hr />
                      <span className='between'>
                        <span>
                          <p><strong>{experiencia.cargo} - {experiencia.nomeEmpresa}</strong></p>
                          <p className='texto-opaco'>
                            {(new Date(experiencia.dataInicio).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(experiencia.dataInicio).getFullYear().toString()}
                            {" - "}
                            {experiencia.dataTermino ? (new Date(experiencia.dataTermino).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(experiencia.dataTermino).getFullYear().toString() : "Cursando"}
                          </p>
                          <p className='texto-opaco'>{experiencia.regimeContratacao}</p>
                        </span>
                        <span className='icones'>
                          <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => abrirModalExperiencia(experiencia)} />
                          <img src={removerIcon} alt="" className='iconEditarModal' onClick={() => deletarExperienciaProfissional(experiencia.id)} />
                        </span>
                      </span>
                    </section>
                  ))
                }
              </div>
            </section>
            <section className='sessao-inf-usuario'>
              <div className='curriculoAbout'>
                <div className='container-editar'>
                  <h2>AVALIAÇÃO DISC</h2>
                  {candidato?.discAvaliacaos && candidato?.discAvaliacaos.length === 0 && (
                    <img src={adicionarIcon} alt="" className='iconEditarModal' onClick={() => exibirModal("modalAvaliacaoDisc")} />
                  )}
                </div>
                <section>
                  {candidato?.discAvaliacaos.map((avaliacao) =>
                  (
                    <section>
                      <span>
                        <p><strong>Avaliação realizada em: {(new Date(avaliacao.dataAvaliacao)).toLocaleDateString()}</strong></p>
                      </span>
                      <div className="chart-body">
                        <div className="chart-serie" style={{ backgroundImage: "linear-gradient(to bottom, #e20000, #a80101)", maxHeight: `${(avaliacao.dominante / 80) * 100}%` }}>
                          <h3 className="chart-title">{(avaliacao.dominante / 80) * 100}%</h3>
                          <label htmlFor="">Dominante</label>
                        </div>
                        <div className="chart-serie" style={{ backgroundImage: "linear-gradient(to bottom, #e7e756, #9b9b39)", maxHeight: `${(avaliacao.influente / 80) * 100}%` }}>
                          <h3 className="chart-title">{(avaliacao.influente / 80) * 100}%</h3>
                          <label htmlFor="">Influente</label>
                        </div>
                        <div className="chart-serie" style={{ backgroundImage: "linear-gradient(to bottom, #ac6706, #704304)", maxHeight: `${(avaliacao.estavel / 80) * 100}%` }}>
                          <h3 className="chart-title">{(avaliacao.estavel / 80) * 100}%</h3>
                          <label htmlFor="">Estavel</label>
                        </div>
                        <div className="chart-serie" style={{ backgroundImage: "linear-gradient(to bottom, #001ee2, #01169b)", maxHeight: `${(avaliacao.condescendente / 80) * 100}%` }}>
                          <h3 className="chart-title">{(avaliacao.condescendente / 80) * 100}%</h3>
                          <label htmlFor="">Condescendente</label>
                        </div>
                      </div>
                    </section>
                  )
                  )
                  }
                </section>
              </div>
            </section>
          </div>
        </section>
      </section>

      {modal.nome === "modalSobre" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={submitDadosModal}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Sobre mim</h1>
                </span>
              </section>
              <section className="sectionModal">
                <textarea name="sobreMim" rows={15} placeholder='Fale um pouco sobre você' className='textAreaSobreMim' onChange={(e) => setDadosSobreMim(e.target.value)} maxLength={500} value={candidato ? dadosSobreMim : "Adicione um sobre Mim"}></textarea>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalDadosPessoais" &&
        <div className='fundoModal'>
          <section className={`containerModalCentro ${!visivel ? '' : 'hide'}`}>
            <form className="formModal">
              <section>
                <span className="novoContainerTitulo">
                  <h1>Dados Pessoais</h1>
                </span>
              </section>
              <section className="sectionModal">
                <span>
                  <label htmlFor="">Nome Completo</label>
                  <input type="text" name="" defaultValue={candidato && candidato.nomeCompleto} placeholder='Digite seu Nome Completo' onChange={(e) => setNomeCompleto(e.target.value)} required />
                </span>
                <span>
                  <label htmlFor="">E-mail</label>
                  <input type="email" name="" defaultValue={candidato && candidato.email} placeholder='Preencha com seu E-mail' disabled />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Telefone</label>
                  <input type="text" value={telefone}
                    minLength={18}
                    maxLength={18}
                    placeholder='(XX) X.XXXX - XXXX'
                    onChange={e => setTelefone(telefoneMascara(e.target.value))} required />
                </span>
                <span>
                  <label htmlFor="">Profissão</label>
                  <input type="text" name="" defaultValue={candidato && candidato.profissao} placeholder='Adicione sua Profissão' onChange={(e) => handleChange(e, setProfissao)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Data Nascimento</label>
                  <input type="date" name="" defaultValue={dataNascimento} onChange={(e) => handleChange(e, setDataNascimento)} />
                </span>
                <span></span>
              </section>

              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => mudarVisibilidadeModal()}>
                  {!processando && 'Continuar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
          {/* Endereço */}
          <section className={`containerModalCentro ${visivel ? '' : 'hide'}`}>
            <form className="formModal" onSubmit={submitDadosModal}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Endereço</h1>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Rua</label>
                  <input type="text" name="" defaultValue={candidato && candidato.rua} placeholder='Digite o Nome da Rua' onChange={(e) => handleChange(e, setRua)} />
                </span>
                <span>
                  <label htmlFor="">Numero</label>
                  <input type="number" name="" defaultValue={candidato && candidato.numero} placeholder='Digite o Numero da Rua' onChange={(e) => handleChange(e, setNumero)} />
                </span>
              </section>

              <section>
                <span>
                  <label htmlFor="">Bairro</label>
                  <input type="text" name="" defaultValue={candidato && candidato.bairro} placeholder='Digite o Nome do Bairro' onChange={(e) => handleChange(e, setBairro)} />
                </span>
                <span>
                  <label htmlFor="">CEP</label>
                  <input type="text" name="" value={cep} placeholder='Digite o CEP' minLength={11} maxLength={11} onChange={e => setCep(cepMascara(e.target.value))} />
                </span>
              </section>

              <section>
                <span>
                  <label htmlFor="estados">Estado</label>
                  <select value={`${estado}-${uf}`} onChange={(e) => handleChangeEstado(e)}>
                    <option value="Acre-AC">Acre</option>
                    <option value="Alagoas-AL">Alagoas</option>
                    <option value="Amapá-AP">Amapá</option>
                    <option value="Amazonas-AM">Amazonas</option>
                    <option value="Bahia-BA">Bahia</option>
                    <option value="Ceará-CE">Ceará</option>
                    <option value="Distrito Federal-DF">Distrito Federal</option>
                    <option value="Espírito Santo-ES">Espírito Santo</option>
                    <option value="Goiás-GO">Goiás</option>
                    <option value="Maranhão-MA">Maranhão</option>
                    <option value="Mato Grosso-MT">Mato Grosso</option>
                    <option value="Mato Grosso do Sul-MS">Mato Grosso do Sul</option>
                    <option value="Minas Gerais-MG">Minas Gerais</option>
                    <option value="Pará-PA">Pará</option>
                    <option value="Paraíba-PB">Paraíba</option>
                    <option value="Paraná-PR">Paraná</option>
                    <option value="Pernambuco-PE">Pernambuco</option>
                    <option value="Piauí-PI">Piauí</option>
                    <option value="Rio de Janeiro-RJ">Rio de Janeiro</option>
                    <option value="Rio Grande do Norte-RN">Rio Grande do Norte</option>
                    <option value="Rio Grande do Sul-RS">Rio Grande do Sul</option>
                    <option value="Rondônia-RO">Rondônia</option>
                    <option value="Roraima-RR">Roraima</option>
                    <option value="Santa Catarina-SC">Santa Catarina</option>
                    <option value="São Paulo-SP">São Paulo</option>
                    <option value="Sergipe-SE">Sergipe</option>
                    <option value="Tocantins-TO">Tocantins</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="">Cidade</label>
                  <input type="text" name="" defaultValue={candidato && candidato.cidade} placeholder='Digite a Cidade onde vive' onChange={(e) => handleChange(e, setCidade)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Complemento</label>
                  <input type="text" name="" defaultValue={candidato && candidato.complemento} placeholder='Digite um Complemento' onChange={(e) => handleChange(e, setComplemento)} />
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={false} onClick={() => setVisivel(false)}>Voltar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalAvatar" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={enviarFotoPerfil}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Atualizar foto de perfil</h1>
                </span>
              </section>
              <section>
                <span>
                  <div className='container-mudar-fotoPerfil'>
                    {avatarPreview && <img src={avatarPreview} alt="" className='fotoPerfil' />}
                  </div>
                  <label htmlFor="">Selecione uma imagem</label>
                  <input type="file" name="fotoPerfil" accept='image/*' onChange={alterarFotoPerfil} />
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalEditarFormacao" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={editarFormacaoAcademica}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Formação Academica</h1>
                </span>
              </section>

              <section className="sectionModal">
                <span>
                  <label htmlFor="">Curso</label>
                  <input type="text" name="" defaultValue={formacaoAcademicaSelecionado?.curso} onChange={(e) => setNomeCurso(e.target.value)} />
                </span>
                <span>
                  <label>Data início</label>
                  <input type="month" defaultValue={formacaoAcademicaSelecionado && new Date(formacaoAcademicaSelecionado.dataInicio).toISOString().slice(0, 7)}
                    onChange={(e) => setDataInicio(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Nível</label>
                  <select defaultValue={formacaoAcademicaSelecionado?.nivel} onChange={(e) => setNivelFormacao(e.target.value)}>
                    <option value="" disabled>Selecione um Nível</option>
                    <option value="Nível Fundamental">Nível Fundamental</option>
                    <option value="Nível Médio">Nível Médio</option>
                    <option value="Nível Superior">Nível Superior</option>
                    <option value="Pós - Graduação">Pós - Graduação</option>
                    <option value="Mestrado">Mestrado</option>
                    <option value="Doutorado">Doutorado</option>
                    <option value="Phd">Phd</option>
                  </select>
                </span>
                <span>
                  <label>Data termino</label>

                  <input type="month" defaultValue={formacaoAcademicaSelecionado && formacaoAcademicaSelecionado.dataTermino ? new Date(formacaoAcademicaSelecionado.dataTermino).toISOString().slice(0, 7) : ""} onChange={(e) => setDataFinal(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Instituição de Ensino</label>
                  <input type="text" defaultValue={formacaoAcademicaSelecionado?.instituicaoEnsino} onChange={(e) => setInstituicaoEnsino(e.target.value)} />
                </span>
                <span>
                  <label htmlFor="">Situação do curso</label>
                  <select defaultValue={formacaoAcademicaSelecionado?.situacaoCurso} onChange={(e) => setSituacaoCurso(e.target.value)}>
                    <option value="Cursando">Cursando</option>
                    <option value="Concluído">Concluído</option>
                    <option value="Incompleto">Incompleto</option>
                  </select>
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalAdicionarFormacao" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={adicionarFormacaoAcademica}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Formação Academica</h1>
                </span>
              </section>

              <section className="sectionModal">
                <span>
                  <label htmlFor="">Curso</label>
                  <input type="text" name="" placeholder="Digite o nome do curso" onChange={(e) => setNomeCurso(e.target.value)} />
                </span>
                <span>
                  <label>Data início</label>
                  <input type="month" onChange={(e) => setDataInicio(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Nível</label>
                  <select defaultValue="" onChange={(e) => setNivelFormacao(e.target.value)}>
                    <option value="" disabled>Selecione um Nível</option>
                    <option value="Nível Fundamental">Nível Fundamental</option>
                    <option value="Nível Médio">Nível Médio</option>
                    <option value="Nível Superior">Nível Superior</option>
                    <option value="Pós - Graduação">Pós - Graduação</option>
                    <option value="Mestrado">Mestrado</option>
                    <option value="Doutorado">Doutorado</option>
                    <option value="Phd">Phd</option>
                  </select>
                </span>
                <span>
                  <label>Data termino</label>
                  <input type="month" onChange={(e) => setDataFinal(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Instituição de Ensino</label>
                  <input type="text" placeholder="Digite o nome da instituição" onChange={(e) => setInstituicaoEnsino(e.target.value)} />
                </span>
                <span>
                  <label htmlFor="">Situação do curso</label>
                  <select defaultValue="" onChange={(e) => setSituacaoCurso(e.target.value)}>
                    <option value="" disabled>Selecione um Curso</option>
                    <option value="Cursando">Cursando</option>
                    <option value="Concluído">Concluído</option>
                    <option value="Incompleto">Incompleto</option>
                  </select>
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalEditarCurso" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={editarCursoComplementar}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Curso complementar</h1>
                </span>
              </section>

              <section className="sectionModal">
                <span>
                  <label htmlFor="">Curso</label>
                  <input type="text" name="" defaultValue={cursoComplementarSelecionado?.curso} onChange={(e) => setNomeCurso(e.target.value)} />
                </span>
                <span>
                  <label>Data início</label>
                  <input type="month" defaultValue={cursoComplementarSelecionado && new Date(cursoComplementarSelecionado.dataInicio).toISOString().slice(0, 7)}
                    onChange={(e) => setDataInicio(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Carga horária</label>
                  <input type="number" defaultValue={cursoComplementarSelecionado?.cargaHoraria} onChange={(e) => setCargaHoraria(parseInt(e.target.value))} />
                </span>
                <span>
                  <label>Data termino</label>
                  <input type="month" defaultValue={cursoComplementarSelecionado?.dataTermino ? new Date(cursoComplementarSelecionado.dataTermino).toISOString().slice(0, 7) : ""} onChange={(e) => setDataFinal(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="" >Instituição de Ensino</label>
                  <input type="text" defaultValue={instituicaoEnsino} onChange={(e) => setInstituicaoEnsino(e.target.value)} />
                </span>
                <span>
                  <label htmlFor="">Situação do curso</label>
                  <select defaultValue={cursoComplementarSelecionado?.situacaoCurso} onChange={(e) => setSituacaoCurso(e.target.value)}>
                    <option value="Cursando">Cursando</option>
                    <option value="Concluído">Concluído</option>
                    <option value="Incompleto">Incompleto</option>
                  </select>
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalAdicionarCurso" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={adicionarCursoComplementar}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Curso Complementar</h1>
                </span>
              </section>

              <section className="sectionModal">
                <span>
                  <label htmlFor="">Curso</label>
                  <input type="text" name="" placeholder="Digite o nome do curso" onChange={(e) => setNomeCurso(e.target.value)} />
                </span>
                <span>
                  <label>Data início</label>
                  <input type="month" onChange={(e) => setDataInicio(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Carga Horaria</label>
                  <input type="number" name="" placeholder='Digite a carga horaria' onChange={(e) => setCargaHoraria(parseInt(e.target.value))} />
                </span>
                <span>
                  <label>Data termino</label>
                  <input type="month" onChange={(e) => setDataFinal(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Instituição de Ensino</label>
                  <input type="text" placeholder="Digite o nome da instituição" onChange={(e) => setInstituicaoEnsino(e.target.value)} />
                </span>
                <span>
                  <label htmlFor="">Situação do curso</label>
                  <select defaultValue="" onChange={(e) => setSituacaoCurso(e.target.value)}>
                    <option value="" disabled>Selecione um Curso</option>
                    <option value="Cursando">Cursando</option>
                    <option value="Concluído">Concluído</option>
                    <option value="Incompleto">Incompleto</option>
                  </select>
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalAdicionarExperiencia" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={adicionarExperienciaProfissional}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>EXPERIENCIA PROFISSIONAL</h1>
                </span>
              </section>

              <section className="sectionModal">
                <span>
                  <label htmlFor="">Cargo</label>
                  <input type="text" placeholder='Digite o nome do cargo' onChange={(e) => setCargo(e.target.value)} required />
                </span>
                <span>
                  <label htmlFor="">Data Início</label>
                  <input type="month" placeholder='dd/mm' onChange={(e) => setDataInicio(e.target.value)} required />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Nome da Empresa</label>
                  <input type="text" placeholder='Digite o nome da Empresa' onChange={(e) => setNomeEmpresa(e.target.value)} required />
                </span>
                <span>
                  <label htmlFor="">Data Termino</label>
                  <input type="month" placeholder='dd/mm' onChange={(e) => setDataFinal(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Tipo de Contrato</label>
                  <select name="" onChange={(e) => setTipoContrato(e.target.value)} required>
                    <option value="Estágio">Estágio</option>
                    <option value="Clt">Clt</option>
                    <option value="Contrato">Contrato</option>
                    <option value="Diarista">Diarista</option>
                    <option value="Outros">Outros</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="">Cargo é de gestão?</label>
                  <select name="" defaultValue="0" onChange={(e) => setCargoLideranca(e.target.value)} required>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="">Cargo é temporario?</label>
                  <select name="" defaultValue="0" onChange={(e) => setCargoTemporario(e.target.value)} required>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Principais Atribuições</label>
                  <textarea name="sobreMim" rows={12} placeholder='Descreva as principais responsabilidades que você desempenhou na empresa.' maxLength={500} onChange={(e) => setPrincipaisAtribuicoes(e.target.value)} required></textarea>
                </span>
              </section>

              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalEditarExperiencia" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={editarExperienciaProfissional}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>EXPERIENCIA PROFISSIONAL</h1>
                </span>
              </section>

              <section className="sectionModal">
                <span>
                  <label htmlFor="">Cargo</label>
                  <input type="text" placeholder='Digite o nome do cargo' defaultValue={experienciaProfissionalSelecionado?.cargo} onChange={(e) => setCargo(e.target.value)} required />
                </span>
                <span>
                  <label htmlFor="">Data Início</label>
                  <input type="month" placeholder='dd/mm' defaultValue={experienciaProfissionalSelecionado && new Date(experienciaProfissionalSelecionado.dataInicio).toISOString().slice(0, 7)} onChange={(e) => setDataInicio(e.target.value)} required />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Nome da Empresa</label>
                  <input type="text" placeholder='Digite o nome da Empresa' defaultValue={experienciaProfissionalSelecionado?.nomeEmpresa} onChange={(e) => setNomeEmpresa(e.target.value)} required />
                </span>
                <span>
                  <label htmlFor="">Data Termino</label>
                  <input type="month" placeholder='dd/mm' defaultValue={experienciaProfissionalSelecionado?.dataTermino ? new Date(experienciaProfissionalSelecionado.dataTermino).toISOString().slice(0, 7) : ""} onChange={(e) => setDataFinal(e.target.value)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Tipo de Contrato</label>
                  <select name="" defaultValue={experienciaProfissionalSelecionado?.regimeContratacao} onChange={(e) => setTipoContrato(e.target.value)} required>
                    <option value="Estágio">Estágio</option>
                    <option value="Clt">Clt</option>
                    <option value="Contrato">Contrato</option>
                    <option value="Diarista">Diarista</option>
                    <option value="Outros">Outros</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="">Cargo é de gestão?</label>
                  <select name="" defaultValue={experienciaProfissionalSelecionado?.cargoLideranca ? 1 : 0} onChange={(e) => setCargoLideranca(e.target.value)} required>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="">Cargo é temporario?</label>
                  <select name="" defaultValue={experienciaProfissionalSelecionado?.tempoDeterminado ? 1 : 0} onChange={(e) => setCargoTemporario(e.target.value)} required>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Principais Atribuições</label>
                  <textarea name="sobreMim" rows={12} placeholder='Descreva as principais responsabilidades que você desempenhou na empresa.' defaultValue={experienciaProfissionalSelecionado?.principaisAtribuicoes} maxLength={500} onChange={(e) => setPrincipaisAtribuicoes(e.target.value)} required></textarea>
                </span>
              </section>

              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modal.nome === "modalArea" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={adicionarInteresseArea}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Área de Interesse</h1>
                </span>
              </section>
              <span>
                <label htmlFor=""></label>
                <select onChange={(e) => setDescricaoArea(e.target.value)}>
                  <option defaultValue="" selected disabled>Selecione uma Área</option>
                  <option value="Tecnologia Da Informação">Tecnologia Da Informação</option>
                  <option value="Saúde">Saúde</option>
                  <option value="Engenharia">Engenharia</option>
                  <option value="Finanças e Contabilidade">Finanças e Contabilidade</option>
                  <option value="Administrativo">Administrativo</option>
                  <option value="Atendimento">Atendimento</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Vendas">Vendas</option>
                  <option value="Educação">Educação</option>
                  <option value="Recursos Humanos">Recursos Humanos</option>
                  <option value="Construção Civil">Construção Civil</option>
                  <option value="Logística">Logística</option>
                </select>
              </span>
              <span>
                {
                  candidato?.interesseAreas.map((area) => (
                    <section>
                      <p>{area.descricao}</p>
                      <img src={removerIcon} alt="" className='iconEditarModal' onClick={() => excluirAreaInteresse(area.id)} />
                    </section>

                  ))
                }
              </span>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }

      {modal.nome === "modalRegime" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={adicionarInteresseRegime}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Regimes de Interesse</h1>
                </span>
              </section>
              <span>
                <label htmlFor=""></label>
                <select name="Estágio" onChange={(e) => setDescricaoArea(e.target.value)}>
                  <option defaultValue="" selected disabled>Selecione um Regime</option>
                  <option value="Estágio">Estágio</option>
                  <option value="Clt">Clt</option>
                  <option value="Contrato">Contrato</option>
                  <option value="Diarista">Diarista</option>
                  <option value="Outros">Outros</option>
                </select>
              </span>
              <span>
                {
                  candidato?.interesseRegimes.map((interesse) => (
                    <section>
                      <p>{interesse.descricao}</p>
                      <img src={removerIcon} alt="" className='iconEditarModal' onClick={() => excluirInteresseRegime(interesse.id)} />
                    </section>
                  ))
                }
              </span>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }

      {modal.nome === "modalAvaliacaoDisc" &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal">
              <section>
                <span className="novoContainerTitulo">
                  <h1>Avaliação de PerFil Comportamental</h1>
                </span>
              </section>
              <section>
                <h3>Pronto para realizar o perfil comportamental?</h3>
              </section>
              <section>
                <span>
                  <p>Indique o quanto você tem afinidade com a situação descrita, usando a seguinte escala:</p>
                </span>
              </section>
              <section>
                <span>
                  <p>1. Pouca Afinidade</p>
                  <p>2. Alguma Afinidade</p>
                  <p>3. Afinidade Moderada</p>
                  <p>4. Total Afinidade</p>
                  <hr />
                </span>
              </section>
              <section>
                <span className="texto-opaco">
                  <p>Dicas:</p>
                  <p>1. Escolha um momento tranquilo</p>
                  <p>
                    2. Responda de forma autêntica.
                  </p>
                  <p>
                    3. Dedique um tempo específico para realizar o teste.
                  </p>
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => exibirModal("")}>Cancelar</Button>
                {avaliacaoSelecionado ?
                  <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => continuarAvaliacaoDisc()}>
                    {!processando && 'Continuar'}
                    {processando && <Loader isLoading={processando} />}
                  </Button> : <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => criarAvaliacaoDisc()}>
                    {!processando && 'Começar'}
                    {processando && <Loader isLoading={processando} />}
                  </Button>}
              </div>
            </form>
          </section>
        </div>}
      {modal.nome === "modalQuestoesDisc" && <ModalDisc perguntasDisc={perguntasDisc} idAvaliacao={avaliacaoSelecionado} />}
    </section>
  )
}

export default Curriculo