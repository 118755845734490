import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalAlertaReduce = createSlice ({
    name: 'modalAlerta',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalAlerta (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalAlerta (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalAlerta, setOcultarModalAlerta } = modalAlertaReduce.actions

export default modalAlertaReduce.reducer