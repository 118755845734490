import confirmacaoImg from '../../assets/img/confirmacaoOperacao.gif'
import confirmacaoOperacaoProps from '../../interfaces/propsConfirmacaoOperacao'
import './confirmacaooperacao.css'

function ConfirmacaoOperacao (props: confirmacaoOperacaoProps) {
    return (
        <div id = 'confirmacao_operacao'>
            <div id = 'confirmacao_dados_operacao'>
                <img id = 'confirmacao_operacao_img' src= {confirmacaoImg} alt = 'imagem de confirmação da operação'></img>
                <p>{props.texto}</p>
            </div>
        </div>
    )
}

export default ConfirmacaoOperacao