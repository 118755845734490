import Auth from '../Auth'
import './portal.css'

function PortalAuth () {

    return (
        <div id='bancoTalentoContainer'>
            <Auth/>
        </div>
    )
}

export default PortalAuth