import { useEffect, useState, FormEvent } from 'react'
import { telefoneMascara } from '../../helpers'
import { novoLead } from '../../services/leads'
import Header from '../Header'
import Footer from '../Footer'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import atendimentoIcone from '../../assets/img/atendimento.png'
import './contato.css'
import Link from '../Menu'

function Contato () {

    const [nome, setNome] = useState ('')
    const [telefone, setTelefone] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [email, setEmail] = useState('')
    const [formaConhecimento, setFormaConhecimento] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [processando, setProcessando] = useState(false)
    const [confirmacao, setConfirmacao] = useState(false)
    const [erro, setErro] = useState (false)


    const telefoneChange = (e: any) => {

        const { value } = e.target

        setTelefone (telefoneMascara (value))
    }

    const cadastrarLead = async (e: FormEvent) => {
        e.preventDefault()

        setProcessando (true)

        const payload = {
            nome,
            telefone,
            empresa,
            email,
            formaConhecimento,
            mensagem
        }

        try {

            const resposta = await novoLead (payload)

            if (resposta.status === 201) {
                setProcessando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                }, 2500)
            }
            
        } catch (error) {
            setProcessando (false)
            setErro (true)

            return setTimeout (() => {
                setErro (false)
            }, 2500)
            
        }
    }

    useEffect (() => {
        document.title = 'Fale Conosco'
    }, [])
    
    return (
        <div id = 'paginaContato'>
            {processando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Enviados com Sucesso!' />}
            {erro && <ErrorOperacao texto='Falha ao enviar os dados, tente novamente!'/>}
            <Header />
            <div id='conteudoContienerContato'>
                <span id='cabecalhoFormulario'>
                    <img id='atencimentoIcone' src={atendimentoIcone} alt = '' />
                    <p>Preencha os dados ao lado, nossa equipe de atendimento responde em até 24 horas úteis</p>
                </span>
                <section id = 'formularioContiener'>
                    <form id = 'formNovoLead' onSubmit={cadastrarLead}>
                        <span>
                            <span id ='inputsLead'>
                                <label htmlFor="nomeLead">Nome Completo <b>*</b></label>
                                <input type="text"  placeholder='ex: Fernanda de Melo' id='nomeLead' value={nome} onChange={(e) => setNome(e.target.value)} required/>
                            </span>
                            <span id ='inputsLead'>
                                <label htmlFor="leadTelefone">Telefone <b>*</b></label>
                                <input type="text" placeholder='ex: (82) 9.9999 - 0000' id='leadTelefone' minLength={18} maxLength={18} value={telefone} onChange={telefoneChange} required/>
                            </span>
                        </span>
                        <span>
                            <span id='inputsLead'>
                                <label htmlFor="empresaLead">Empresa</label>
                                <input type="text" placeholder='ex: Hg Consultoria' id='empresaLead' value={empresa} onChange={(e) => setEmpresa(e.target.value)} required/>
                            </span>
                            <span id='inputsLead'>
                                <label htmlFor="emailLead">E-mail <b>*</b></label>
                                <input type="email" placeholder='ex: hg@meuhg.com' id='emailLead' value={email} onChange={(e) => setEmail(e.target.value)} required/>
                            </span>
                        </span>
                        <br/>
                        <span>
                            <span id='inputsLead'>
                                <label htmlFor="formaConhecimento">Como você nos conheceu? <b>*</b></label>
                                <select name="formaConhecimento" id="formaConhecimento" value={formaConhecimento} onChange={(e) => setFormaConhecimento(e.target.value)} required >
                                    <option value=""></option>
                                    <option value="google">Google</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="instagram">Instagram</option>
                                    <option value="indicação de amigos">Indicação de Amigos</option>
                                    <option value="participação em eventos">Participação em Eventos</option>
                                    <option value="outros">Outros</option>
                                </select>
                            </span>
                        </span>
                        <br />
                        <span>
                            <span id='inputsLead'>
                                <label htmlFor="mensagemLead">Mensagem<b>*</b></label>
                                <textarea name="" id="mensagemLead" rows={10} value={mensagem} onChange={(e) => setMensagem(e.target.value)} placeholder='conte-nos o que você está procurando!' required></textarea>
                            </span>
                        </span>
                            <div id = 'consentimentoFormulario'>
                                <input id='checkBoxConsentimento' type='checkbox' required/>
                                <label id = 'aceitePoliticaPrivacidade' htmlFor="checkBoxConsentimento">Eu li e concordo com a <Link texto='Política de Privacidade' redirect='/politicaprivacidade' /></label>
                            </div>
                        <span>
                            <button type='submit' id='btnEnviarDados'>ENVIAR</button>
                        </span>
                    </form>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Contato