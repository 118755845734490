import { useEffect } from 'react'
import Curriculo from '../../components/Curriculo'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

function PortalCandidato() {
  
    useEffect(() => {

        document.title = 'Portal do Candidato'

    }, [])

    return (
        <div>
            <Header/>
            <Curriculo/>
            <Footer/>
        </div>
  )
}

export default PortalCandidato