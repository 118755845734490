import { useState, useEffect, FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import { nps } from '../../services/nps'
import atendimentoIcone from '../../assets/img/atendimento.png'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import './nps.css'

function Nps () {

    const { organizacao, responsavel, tarefa } = useParams ()
    const [solicitacao, setSolicitacao] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState (false)
    const [erroDiverso, setErroDiverso] = useState (false)
    const [nota, setNota] = useState<null | number> (null)
    const [notaSeleciona, setNoTaSelecionada] = useState('')
    const [comentario, setComentario] = useState <null | string> (null)

    function cadastrarAvaliacao (e: FormEvent) {
        e.preventDefault()

        const novaAvaliacao = async () => {

            setSolicitacao (true)

            const parms = {
                organizacao: organizacao === undefined ? '' : organizacao,
                responsavel: responsavel === undefined ? '' : responsavel,
                tarefa: parseInt (tarefa === undefined ? '' : tarefa)
            }

            const payload = {
                nota: (nota === null ? 0 : nota),
                comentario
            }

            if (nota === null) {
                setSolicitacao (false)
                return alert ('Clique na nota para selecionar')
            }

            try {
    
                const resposta = await nps (parms, payload)

                if (resposta.status === 201) {
                    setSolicitacao (false)
                    setConfirmacao (true)

                    return setTimeout (() => {
                        setConfirmacao (false)
                    }, 3000)
                }
                
            } catch (error: any) {

                if (error.response.status === 403) {
                    setSolicitacao (false)
                    setErro (true)

                    return setTimeout (() => {
                        setErro (false)
                        
                    }, 3500)
                }

                setSolicitacao (false)
                setErroDiverso (true)
    
                return setTimeout(() => {
                   setErroDiverso (false) 
                }, 3000);
            }
        }

        novaAvaliacao ()
    }

    function notaEscolhida (valor: number) {
        setNota(valor)
        setNoTaSelecionada(`nota${valor.toString()}`)
    }

    useEffect (() => {
        document.title = 'Avaliação do Atendimento'
      })

    return (
        <div id = 'paginaNps'>
            {solicitacao && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Atendimento Avaliado com Sucesso' />}
            {erro && <ErrorOperacao texto='Atendimento Já Avaliado' />}
            {erroDiverso && <ErrorOperacao  texto='Falha ao Salvar os Dados'/>}
            <div id = 'conteudoContienerNps'>
                <span id='cabecalhoFormulario'>
                    <img id='atencimentoIcone' src={atendimentoIcone} alt = '' />
                    <p>Para gente é muito importante a sua nota. Avalie o atendimento recebido e, se possível, deixe um comentário</p>
                </span>
                <section id = 'formularioContienerNps'>
                    <form id = 'npsForm' onSubmit={cadastrarAvaliacao}>
                        <div>
                            <p>Em nota de 0 à 10, como você avalia o seu último atendimento recebido?</p>
                        </div>
                        <div id = 'npsNota'>
                            <span id={notaSeleciona === 'nota0' ? notaSeleciona : ''} onClick={() => notaEscolhida(0)}>
                                <h1>0</h1>
                            </span>
                            <span id={notaSeleciona === 'nota1' ? notaSeleciona : ''} onClick={() => notaEscolhida(1)}>
                                <h1>01</h1>
                            </span>
                            <span id={notaSeleciona === 'nota2' ? notaSeleciona : ''} onClick={() => notaEscolhida(2)}>
                                <h1>02</h1>
                            </span>
                            <span id={notaSeleciona === 'nota3' ? notaSeleciona : ''} onClick={() => notaEscolhida(3)}>
                                <h1>03</h1>
                            </span>
                            <span id={notaSeleciona === 'nota4' ? notaSeleciona : ''} onClick={() => notaEscolhida(4)}>
                                <h1>04</h1>
                            </span>
                            <span id={notaSeleciona === 'nota5' ? notaSeleciona : ''} onClick={() => notaEscolhida(5)}>
                                <h1>05</h1>
                            </span>
                            <span id={notaSeleciona === 'nota6' ? notaSeleciona : ''} onClick={() => notaEscolhida(6)}>
                                <h1>06</h1>
                            </span>
                            <span id={notaSeleciona === 'nota7' ? notaSeleciona : ''} onClick={() => notaEscolhida(7)}>
                                <h1>07</h1>
                            </span>
                            <span id={notaSeleciona === 'nota8' ? notaSeleciona : ''} onClick={() => notaEscolhida(8)}>
                                <h1>08</h1>
                            </span>
                            <span id={notaSeleciona === 'nota9' ? notaSeleciona : ''} onClick={() => notaEscolhida(9)}>
                                <h1>09</h1>
                            </span>
                            <span id={notaSeleciona === 'nota10' ? notaSeleciona : ''} onClick={() => notaEscolhida(10)}>
                                <h1>10</h1>
                            </span>
                        </div>

                        <div id = 'npsComentario'>
                            <textarea  
                                placeholder = 'você pode adicionar a sua avaliação uma sugestão, crítica ou elogio.'
                                onChange={(e) => setComentario (e.target.value)}/>
                        </div>

                        <div id = 'npsEnviarDados'>
                            <button type='submit' id='btnEnviarDados'>ENVIAR</button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default Nps