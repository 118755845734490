import Header from "../../components/Header";
import IntroducaoHome from "../../components/IntroducaoHome";
import Servicos from "../../components/Servicos";
import Footer from "../../components/Footer";
import Depoimentos from "../../components/Depoimentos";

function Home() {

    return (
        <>
            <Header />
            <IntroducaoHome />
            <Servicos />
            <Depoimentos />
            <Footer />
        </>
    )
}

export default Home
