import { Navigate } from "react-router-dom"
import { Autenticacao } from "../Token"
import propsComponentePrivado from "../../interfaces/propsComponentePrivado"

const ComponentePrivado = ( props: propsComponentePrivado) => {

    const usuario = Autenticacao ()

    if (!usuario) {
        return <Navigate to='/portal-auth'></Navigate>
    }
    
    return props.children

}

export default ComponentePrivado