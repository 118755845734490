import { Provider } from "react-redux"
import { AppProvider } from "./providers"
import Routes from "./routes/Routes"
import store from "./store"
import "./styles/global.css"

function App() {
  
    return (
        <div className="App">
            <Provider store={store}>
                <AppProvider>
                    <Routes />
                </AppProvider>
            </Provider>
        </div>
    )
}

export default App;
