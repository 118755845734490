import axios from "axios";
import { verificarToken } from "../../middlewares/Token";

const BASEAPIFORM = axios.create({
  baseURL: process.env.REACT_APP_API_GESTTOR,
  headers: {
    'Content-Type': 'multipart/form-data',
  }
})

BASEAPIFORM.interceptors.request.use(async config => {
  const token = verificarToken()
  if(token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default BASEAPIFORM