import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalQrCodeReduce = createSlice ({
    name: 'modalQrCode',
    initialState: {
        exibir: false,
        id: 0
    } as ModalEstado,
    reducers: {
        setExibirModalQrCode (state, action) {
            Object.assign (state, {
                exibir: true,
                id: action.payload.idReceita
            })
        },

        setOcultarModalQrCode (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalQrCode, setOcultarModalQrCode } = modalQrCodeReduce.actions

export default modalQrCodeReduce.reducer