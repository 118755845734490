import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Privacidade from  '../../components/PolicaPrivacidade'

function PoliticaPrivacidade () {

  return (
    <>
      <Header/>
      <Privacidade />
      <Footer/>
    </>
  )
}

export default PoliticaPrivacidade;