import Header from "../../components/Header";
import Nps from '../../components/Nps';
import Footer from "../../components/Footer";

function Home() {

  return (
    <>
      <Header />
      <Nps />
      <Footer />
    </>
  )
}

export default Home;
