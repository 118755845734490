import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setOcultarModalQrCode } from '../../store/modules/modalQrCode'
import { setExibirModalAlerta, setOcultarModalAlerta } from '../../store/modules/modalAlerta'
import { gerarQrCode } from '../../services/cobrancas'
import { Button } from '../styles'
import io from 'socket.io-client'
import ProcessandoSolicitacao from '../ProcessandoSolicitacao'
import ModalAlerta from '../ModalAlerta'
import erroImg from '../../assets/img/erroFechar.png'
import confirmacaoImg from '../../assets/img/confirmacao.png'
import './modalqrcode.css'

function ModalQrCode () {

    const atualizarDados = useSelector((store: RootStore) => store.atualizarDados)
    const modalAlerta = useSelector ((store: RootStore) => store.modalAlerta)
    const modalQrCode = useSelector ((store: RootStore) => store.modalQrCode)
    const dispatch = useDispatch ()

    const [processando, setProcessando] = useState(true)
    const [qrCode, setQrCode] = useState({__html: 'Seu QrCode está Sendo Gerado'})
    const [sucessoPagamentoPix, setSucessoPagamentoPix] = useState(false)
    const [erro, setErro] = useState(false)

    function ocultarModalQrCode () {
        dispatch (setOcultarModalQrCode ({
            exibir: false
        }))
    }

    function ocultarModalAlerta () {
        dispatch (setOcultarModalAlerta ({
            exibir: false
        }))

        dispatch (setOcultarModalQrCode ({
            exibir: false
        }))
        
        return setProcessando(true)
    }

    function ocultarModalSucessoPagamentoPix () {

        dispatch (setOcultarModalQrCode ({
            exibir: false
        }))

        dispatch (setAtualizarDados ({
            atualizar: !atualizarDados.atualizar
        }))

        return setSucessoPagamentoPix (false)
    }

    useEffect (() => {
        setErro(modalAlerta.exibir)
    }, [modalAlerta, erro])

    useEffect(() => {
        
        const iniciarSocket = () => {

            const idOrganizacao = process.env.REACT_APP_ORGANIZACAO_HG

            const socket = io(`${process.env.REACT_APP_API_GESTTOR}?idOrganizacao=${idOrganizacao}`)
        
            socket.on('pix', (data: any) => {

                const { idCobranca,  situacao } = data

                if(idCobranca ===  modalQrCode.id && situacao === 'recebida') {
                    
                    setSucessoPagamentoPix(true)

                    dispatch (setAtualizarDados ({
                        atualizar: !atualizarDados.atualizar
                    }))

                    socket.disconnect()
                }
            })

            return () => {
                socket.disconnect()
            }

        }

        iniciarSocket()

    }, [dispatch, atualizarDados, modalQrCode])

    useEffect (() => {

        const qrCode = async () => {

            const idCobranca = modalQrCode.id

            try {

                const resposta = await gerarQrCode(idCobranca)

                if(resposta.status === 200) {
                    setProcessando(false)
                    return setQrCode({__html: resposta.data}) 
                }
                
            } catch (error) {
                setProcessando(false)
                dispatch (setExibirModalAlerta ({
                    exibir: true
                }))
                return setErro (true)
            }
        }

        qrCode ()

    }, [dispatch, modalQrCode.id])

    return (
        
        <div id='modalQrCode'>
            {sucessoPagamentoPix && <ModalAlerta icone={confirmacaoImg} texto='Seu Pagamento foi confirmado com sucesso. Em alguns instantes você receberá o recibo no e-mail que está em seu cadastro.' titulo='Continuar' cor='continuarSucesso' acao={ocultarModalSucessoPagamentoPix}/>}
            {erro && <ModalAlerta icone={erroImg} texto='Ocorreu um erro ao gerar o QrCode. Tente Novamente, se o erro persistir entre em contato com o nosso atendimento.' cor='continuarErro' titulo='Tentar Novamente' acao={ocultarModalAlerta} />}
            {!erro && !sucessoPagamentoPix && <section id = 'modalFundoQrCode'>
                {processando && <ProcessandoSolicitacao textoSuperior='Gerando QrCode' />}
                {!processando && <span dangerouslySetInnerHTML={qrCode} />}
                <div>
                    <p>Quem vai receber este pagamento?</p>
                    <br />
                    <p>Razão Social: <b>SILVA SERVIÇOS EMPRESARAIS LTDA</b></p>
                    <p>Nome Fantasia: <b>HG CONSULTORIA</b></p>
                    <p>CNPJ: <b>19.306.845/0001 - 38</b></p>
                </div>
                <Button isCancelar={false} type='button' cor='cancelar' isLoading={false} onClick={ocultarModalQrCode}>
                    Cancelar Operaão
                </Button>
            </section>}
        </div>
    )
}

export default ModalQrCode