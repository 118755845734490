import Footer from '../../components/Footer';
import Header from '../../components/Header';
import TermosUso from '../../components/TermosUso';

function TermosDeUso () {

  return (
    <>
      <Header/>
      <TermosUso />
      <Footer/>
    </>
  )
}

export default TermosDeUso;