import './carregandocirculo.css'

function CarregandoCirculo () {
    return (
        <div id = 'div_carregando_circulo'>
            <span id = 'carregando_circulo'></span>
        </div>
    )
}

export default CarregandoCirculo